import React from 'react';
import Header from './header';
import Footer from './footer';
import axios from 'axios';
import BaseUrl from '../config/properties';
import '../styles/courses.css';

class Courses extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editModelShow: false,
    };
  }

  componentWillMount() {
      let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
      let authToken = null;
      let userData = [];
      if (isLoggedIn === true)  {
          authToken = localStorage.getItem('authToken');
          userData = JSON.parse(localStorage.getItem('userData'));
          console.log(userData);
          this.setState({
              authToken:authToken,
              userData:userData,
              url:userData.profilePicURL
          });
      }
      
          const config = {
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: localStorage.getItem('authToken')
              }
          }
          axios.get(BaseUrl.base_url+"/api/v1/Courses/ListAllCourses", config).then((response) => {
              this.setState({
                  allCourse: response.data.length && response.data
              })
          }).catch((error) => {
              this.setState({error:error.message})
          });

            axios.get(`https://ipapi.co/json/`).then((response) => {
                        if (response.data) {
                            let countryName = response.data.country_name;
                            //alert('countryName in iapi.get call='+ countryName);
                            localStorage.setItem('countryName', countryName);
                          }
                      }).catch((error) => {
                          this.setState({error: error.message})
                      });      
  }


  render() {
    const { allCourse } = this.state

    return (
        <div>
            <div className="main_div">
                <Header/>
            </div>
            <div className="container">
                <div className="col-md-12 col-sm-12 col-xs-12 article_content">
                    <h2 className="article_innercont">List of Courses</h2>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 article_content">
                    <div>
                        <div className={'col-md-12 col-sm-12'}>
                            <div className="block_locker course-body">
                                <div className="col-md-12 col-sm-12 col-xs-12 block_image account-pg">
                                    {
                                        allCourse && allCourse.map((item,i) => (
                                            <div key={i.toString()} className="col-md-6">
                                                <div className="panel panel-default course-box">
                                                    <div className="row course-div">
                                                        <div className="course-name" onClick={() => this.props.history.push(`/course/${item.microCourse_Name_UrlEncoded}`)}>
                                                            <h4 className="course-name is-link">{item && item.microCourse_Name}</h4>
                                                        </div>
                                                        <div>
                                                            <div className="text-center">
                                                                <img className="course-img text-center" src={item && item.microCourse_ImageURL }/>
                                                            </div>

                                                             <h4 className="course-author">Author:</h4>
                                                             <p className="course-content">{item && item.microCourseCreator_Name}</p><br/>
                                                             <h4 className="course-content">Tags:</h4>
                                                             <p className="course-content">{item && item.microCourse_Tags.replace(/,/g,", ")}</p><br/>
                                                             <h4 className="course-content">Course Price:</h4>
                                                            {
                                                                item && item.microCourse_Price === item && item.microCourse_Discounted_Price ?
                                                                    <div className="col-sm-1">
                                                                        <p className="intro-content">&#36;&nbsp;{item && item.microCourse_Price}</p>
                                                                    </div> :
                                                                    <div>
                                                                        <div className="col-sm-1">
                                                                            <p className="intro-content a-text-strike">&#36;&nbsp;{item && item.microCourse_Price}</p>
                                                                        </div>
                                                                        <div className="col-sm-3">
                                                                           <p className="intro-content">&#36;&nbsp;{item && item.microCourse_Discounted_Price}</p>
                                                                        </div>
                                                                    </div>
                                                            }


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
  }
}

export default Courses;
