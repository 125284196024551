import React from 'react';
import '../styles/TestDetails.css'
import Header from "./header";
import Footer from "./footer";
import axios from "axios";
import { Link } from "react-router-dom"
import BaseUrl from "../config/properties";
import { withRouter } from "react-router-dom";
import "../styles/quiz.css"

class Quiz extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quizList:[],
        };
    }
    componentWillMount() {
        let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        let authToken = null;
        let userData = [];
        if (isLoggedIn === true) {
            authToken = localStorage.getItem('authToken');
            userData = JSON.parse(localStorage.getItem('userData'));
            console.log(userData);
            this.setState({
                authToken:authToken,
                userData:userData,
                url:userData.picture.data.url,
            });
        }
        if (authToken) {
            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('authToken')
                }
            }
            axios.get(BaseUrl.base_url + `/api/v1/Quizzes/ListAllQuizzes`, config)
                .then((response) => {
                if (response.data) {
                    console.log("======------Quiz List------=========>",response.data)
                    this.setState({
                        quizList: response.data,
                    })
                }
            }).catch((error) => {
                this.setState({error: error.message})
            });
        }
    }

    onSendData (data){
        this.props.history.push({
            pathname:`/quiz/${data.quiz_Name_UrlEncoded}`,
            state:{
                data: data
            }
        })
    }

    render() {
        const {quizList} = this.state
        return (

            <div>
                <div className="main_div">
                    <Header/>
                </div>
              
                <div className="index-content">
                    <div className="container quiz-body">
                        <div className="col-md-12 col-sm-12 col-xs-12 article_content">
                            <h2 className="article_innercont">Quiz List</h2>
                        </div>
                        <div>
                        {
                         quizList && quizList.map((item,i) => (
                
                            <div className="col-lg-4">
                                <div className="card">
                                    <h4 style={{paddingTop: "15px", fontSize: "25px"}}>{item.quiz_Name}</h4>
                                    <p>{item.quiz_Description}</p>
                                    <a onClick={() =>this.onSendData(item)} className="blue-button">View Quiz</a>
                                </div>
                            </div>
                            ))
                        }
                        </div>

                    </div>
                </div>


                <Footer/>
            </div>
        );

    }
}

export default Quiz;