import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import SocialLogin from "react-social-login";
require('./styles/style.css');
require('./styles/font-awesome-4.7.0/css/font-awesome.css');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

export default SocialLogin;

