import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import axios from 'axios';
import BaseUrl from '../config/properties';

class InterviewAndPractices extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			data:[]
		};
	}
	
	componentDidMount() {
		//console.log(this.props.careerpath)
		axios.get(BaseUrl.base_url+"/api/v1/CareerPaths/Name/" + this.props.careerpath + "/Interviews_Practice_Content").then((response)=>{
			console.log(response.data);
			this.setState({ 
            	data: response.data
            });
		});
	}
	
	
	render() {
		return(
			<div className="career_interview">
				<div dangerouslySetInnerHTML={{__html:this.state.data}}/>
			</div>



	
		)
	}
}

export default InterviewAndPractices;