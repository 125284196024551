import React from 'react';
import Header from './header';
import Footer from './footer';
// import {Row, Col, Icon, Button} from ' @ant-design/icons'
import {Row, Col, Button} from 'antd'
import axios from 'axios';
import BaseUrl from '../config/properties';
import PayPal from "./PayPal";



import '../styles/courses.css';

const ButtonGroup = Button.Group;



class courseIntro extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editModelShow: false,
      courseComplete: '',
      courseDescription:''
    };
  }

  componentWillMount() {
      const path = window.location.pathname.replace('/','')
      const courseName = path.slice(7);
      let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
      let authToken = null;
      let countryName = '';

      let userData = [];
      if (isLoggedIn === true) {
          authToken = localStorage.getItem('authToken');
          userData = JSON.parse(localStorage.getItem('userData'));
          console.log(userData);
          this.setState({
              authToken:authToken,
              userData:userData,
              url:userData.picture.data.url,
              courseName: courseName
          });
      }
      else {
          this.setState({authToken:null});
      }

      
          const config = {
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: localStorage.getItem('authToken')
              }
          }

          const config1 = {
              headers: {
                  Authorization: localStorage.getItem('authToken')
              }
          }

          countryName = localStorage.getItem('countryName');      
          this.setState({
              countryName:countryName
            });

         
          alert('countryNameFromLocalStorage=' + countryName);
    
          axios.get(BaseUrl.base_url + `/api/v1/Courses/Name/${courseName}/Details?countryName=${countryName}`, config).then((response) => {

              if (response.data) {
                  this.setState({
                      courseDescription: response.data,
                  },() => {this.props.history.push(`/course/${courseName}`)},
                     )
              }
          }).catch((error) => {
              this.setState({error: error.message})
          });

          axios.get(BaseUrl.base_url + `/api/v1/Courses/Name/${courseName}/IsEnrolled`, config).then((response) => {
              if (response.data) {
                  this.setState({
                      coursePay: response.data.status.toLowerCase(),
                  })
              }
          }).catch((error) => {
              this.setState({error: error.message})
          });

          axios.get(BaseUrl.base_url + `/api/v1/Courses/Name/${courseName}/hasUserCompleted`, config).then((response) => {
              if (response.data) {
                  this.setState({
                      courseComplete: response.data.status.toLowerCase(),
                  })
              }
          }).catch((error) => {
              this.setState({error: error.message})
          });
      
  }

  course() {
      const {courseName} = this.state
      const config = {
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: localStorage.getItem('authToken')
          }
      }

      axios.post(BaseUrl.base_url + `/api/v1/Courses/Name/${courseName}/completeCourse`,'', config).then((response) => {
          if (response.data) {
              this.setState({
                  courseComplete: response.data.status.toLowerCase() === 'success' ? 'true' : 'false',
              })
          }
      }).catch((error) => {
          this.setState({error: error.message})
      });
  }

  render() {
  
const { countryName, courseDescription, coursePay, courseComplete,authToken } = this.state
    
    let currencyCode = '';
    let currencyText = '';
    let total= '';
    
  if (countryName === 'Singapore') {

    currencyCode = 'SGD';
    currencyText = 'Singapore Dollar';
    total = parseFloat(courseDescription.microCourse_Discounted_Price);
  }
  else
if (countryName === 'India') {

    currencyCode = 'INR';
    currencyText = 'Indian Rupee';
    total = parseFloat(courseDescription.microCourse_Discounted_Price);

  } else
  if (countryName === 'United States') {

    currencyCode = 'USD';
    currencyText = 'US Dollar';
    total = parseFloat(courseDescription.microCourse_Discounted_Price);
  }
  else {    

    currencyCode = 'USD';
    total = parseFloat(courseDescription.microCourse_Discounted_Price); 
  }

    return (
        <div>
            <div className="main_div">
                <Header/>
            </div>
            <div className="container">
                <div className="col-md-12 col-sm-12 col-xs-12 article_content">
                    <h2 className="article_innercont">Course Description</h2>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 article_content">
                    <div>
                        <div className={'col-md-12 col-sm-12'}>
                            <div className="block_locker course-body">
                                <div className="col-md-12 col-sm-12 col-xs-12 block_image account-pg">
                                    {
                                        courseDescription ?
                                            <Row gutter={8}>
                                                <Col span={4} className="text-center">
                                                    <img height={125} src={courseDescription && courseDescription.microCourse_ImageURL }
                                                         className="course-image"/>
                                                </Col>
                                                <Col span={20}>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <p className="intro-header">Name:</p>
                                                            <p className="intro-content">{courseDescription && courseDescription.microCourse_Name}</p>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <p className="intro-header">Author:</p>
                                                            <p className="intro-content">{courseDescription && courseDescription.microCourseCreator_Name}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <p className="intro-header">Tags:</p>
                                                        <p className="intro-content">{courseDescription && courseDescription.microCourse_Tags}</p>
                                                    </div>

                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <p className="intro-header">Lessons:</p>
                                                            <p className="intro-content">{courseDescription && courseDescription.microLessonsList.length}</p>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <p className="intro-header">Quiz:</p>
                                                            <p className="intro-content">{courseDescription && courseDescription.numQuizzes}</p>
                                                        </div>
                                                    </div>

                                                    <div className="form-group col-md-12">
                                                        <p className="intro-header">Course Description:</p>
                                                        

                                                         <p className="intro-content"> 
                                                          <div dangerouslySetInnerHTML={{ __html: courseDescription && courseDescription.microCourse_Description }} />
                                                         </p>

                                                    </div>

                                                    <div className="form-group col-md-12">
                                                        <p className="intro-header">Course Price:(In {currencyText}) </p>
                                                        {
                                                            courseDescription && courseDescription.microCourse_Price === courseDescription && courseDescription.microCourse_Discounted_Price ?
                                                                <div className="col-sm-1">
                                                                    <p className="intro-content">&nbsp;{courseDescription && courseDescription.microCourse_Price}</p>
                                                                </div> :
                                                                <div>
                                                                    <div className="col-sm-1">
                                                                        <p className="intro-content a-text-strike"> {currencyCode} &nbsp;{courseDescription && courseDescription.microCourse_Price}</p>
                                                                    </div>

                                                                    <div className="col-sm-2">
                                                                        <p className="intro-content">{currencyCode} &nbsp;{courseDescription && courseDescription.microCourse_Discounted_Price}</p>
                                                                    </div>

                                                                </div>
                                                        }
                                                    </div>

                                                    {
                                                        coursePay === 'true' || authToken==null ? null :
                                                            <div className="form-row col-md-12 text-center intro-button">
                                                                <PayPal paypalId={`paypal_${courseDescription.microCourse_Id}`} total={total} 
                                                                        currencyCode={currencyCode} courseId={courseDescription.microCourse_Id} courseName={courseDescription.microCourse_Name_UrlEncoded}/>
                                                            </div>
                                                    }
                                                    {
                                                        coursePay === 'true' ?
                                                            <div className="form-row col-md-12 text-center intro-button">
                                                                {
                                                                    courseComplete === 'true' ?
                                                                        <p className="course-complete">You have already completed this course.You can revisit and learn again anytime!</p>
                                                                        : <Button type="primary" onClick={() => this.course()}>Mark course as completed</Button>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    <div className="form-row col-md-12 text-center intro-button">
                                                        <ButtonGroup>
                                                            <Button type="primary" onClick={() => this.props.history.push('/courses')}>
                                                                {/*<Icon type="left" />Back to Courses*/}
                                                            </Button>
                                                            <Button type="primary" disabled={coursePay === 'false' || authToken==null}
                                                                    onClick={() => this.props.history.push(`/course/${courseDescription.microCourse_Name_UrlEncoded}/Lesson`)}>
                                                                Start Learning
                                                                {/*<Icon type="right" />*/}
                                                            </Button>
                                                        </ButtonGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
  }
}

export default courseIntro;
 