import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Header from './header';
import KnowledgeBlockListing from './knowledgeblocklisting';
import CareerPathList from './careerpathlist';
import Footer from './footer';
import axios from "axios";
import BaseUrl from '../config/properties';

import '../styles/home.css';

const careerpathImage = 'https://s3.amazonaws.com/klockerimg/group-31.png';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state ={ data:[] };
    }
    componentDidMount() {
        axios.get(BaseUrl.base_url+"/api/v1/KnowledgeBlocks/getKnowledgeBlocksForHomepageDisplay/").then((response)=>{
            console.log(response.data);
            this.setState({
                data: response.data,
            });
        });

        axios.get(`https://ipapi.co/json/`).then((response) => {
            if (response.data) {
                let countryName = response.data.country_name;
                //alert('countryName in iapi.get call='+ countryName);
                localStorage.setItem('countryName', countryName);
            }
        }).catch((error) => {
            this.setState({error: error.message})
        });

    }
    componentDidUpdate() {
        ReactDOM.findDOMNode(this).scrollTop = 0
    }

    renderFirstThreeList(obj,i) {
        if(i<3) {
            return (
                <div className="top_links" key={i}>
                    {console.log('obj.link -->', obj.link)}
                    <Link to={obj.link} className="popular_courses">{obj.title}</Link>
                </div>
            )
        }
    }
    renderLastTwoList(obj,i){
        if(i>2) {
            return (
                <div className="top_links" key={i}>
                    <Link to={obj.link} className="popular_courses">{obj.title}</Link>
                </div>
            )
        }
    }

    render() {
        var background = {
            width:'100%',
            backgroundImage : "url(https://s3.amazonaws.com/klockerimg/group-40.png)",
            backgroundRepeat  : 'no-repeat',
            height :"226px",
            backgroundSize : '100% 100%'
        };
        var display ={
            display:'none'
        };
        return (
            <div>
                <div className="main_div_home">
                    <Header />
                    <div className="container">
                        <div className="col-md-12 col-sm-12 col-xs-12 title_content">
                            <p className="title_p">Upskill yourself for the career you seek</p>
                            <div className="top_three text-center">
                                {
                                    this.state.data.map((obj,i) => {
                                        console.log(this.renderFirstThreeList(obj))
                                        return this.renderFirstThreeList(obj,i)
                                    })
                                }
                            </div>
                            <div className="bottom_two text-center second-part">
                                {
                                    this.state.data.map((obj,i) => {
                                        console.log(this.renderLastTwoList(obj))
                                        return this.renderLastTwoList(obj,i)
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 careerpath_section">
                    <p className="careerpath_p">Figuring out which career path to take?</p>
                    <div className ="col-md-12 col-sm-12 col-xs-12 careerpath_content">
                        <div className="col-md-5 col-sm-6 col-xs-12 careerpath_image">
                            <img src="https://s3.amazonaws.com/klockerimg/group-31.png" className="circular_image" />
                        </div>
                        <div className="col-md-7 col-sm-6 col-xs-12 careerpath_options">
                            <div>
                                <p className="para_heading explore_career">Explore Career Paths</p>
                                <p className="para">Discover and Follow Career Paths. View insights from industry professionals</p>
                            </div>
                            <div>
                                <p className="para_heading discover_blocks">Discover Knowledge Blocks</p>
                                <p className="para">The building blocks of what it takes to succeed in a career path.</p>
                            </div>
                            <div>
                                <p className="para_heading craft_learning">Craft a Learning Plan</p>
                                <p className="para">Get appropriate videos and resources delivered to you for continuously upgrading your skill sets</p>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12 col-xs-12 careerpath_listing">
                    <div className ="container">
                        <div className = "fill">
                            <CareerPathList limit="4"/>

                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="careerpath_block">
                                    <div style= { background } >
                                        <div className ="overlay">
                                            <div className = "home-content">
                                                <Link to={"/explorecareerpath/"} ><p className="career_title">Explore More</p></Link>
                                                <p className="plus_para"><Link to={"/explorecareerpath/"} ><span className="plus">+</span></Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mailmunch-forms-widget-620337"></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 knowledgeblock_listing">
                    <div className ="container">
                        <div className = "fill">
                            <div className="col-md-12 col-sm-12 col-xs-12 knowledgeblock_title">
                                <p className="knowledgeblock_title_p">Popular Knowledge Blocks</p>
                                <div className="view_all"><Link to={"/knowledgeblock/"} className="view_all_style">View All</Link></div>
                            </div>
                            <KnowledgeBlockListing display={display}/>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default HomePage;