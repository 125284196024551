import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

import Videos from './videos' 


class VideosAndBlogs extends React.Component {
	
	constructor(props) {
	    super(props);
		console.log(this.props.careerpath);
	 }
	

	render() {
	function handleChange(value) {
	  console.log('selected ${value}');
	}

	function handleBlur() {
	  console.log('blur');
	}

	function handleFocus() {
	  console.log('focus');
	}
		 return (
			<div>
				<div className="col-md-12 col-sm-12 col-xs-12">
					<Videos careerpath={this.props.careerpath} />
				</div>
			</div>
		)
	}
}

export default VideosAndBlogs;
