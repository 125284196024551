import React from 'react';
import { Link } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import SocialFacebook from "../FBcontainers/socialFacebook";
require('../styles/header.css');
const logo='https://s3.amazonaws.com/klockerimg/KL-Logo.png';
const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
console.log({isLoggedIn111:isLoggedIn})

function MobileMenuTab (props) {
    return (
        <div className="col-md-12 drop_down_menu" >
            <ul className="menu_expand">
                <li className="mobile_tabs first_tab"><Link to="/explorecareerpaths" className="header-nav-item-mobile">Career Paths</Link></li>
                <li className="mobile_tabs"><Link to="/knowledgeblocks" className="header-nav-item-mobile">Knowledge Blocks</Link></li>
                <li className="mobile_tabs first_tab"><Link to="/tutorials" className="header-nav-item-mobile">Tutorials</Link></li>
                <li className="mobile_tabs first_tab"><Link to="/courses" className="header-nav-item-mobile">Courses</Link></li>
                <li className="mobile_tabs"><span className="header-nav-item-mobile" onClick={props.showLocker}>My KnowledgeLocker</span></li>
                <SocialFacebook/>
            </ul>
        </div>
    )
}


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            knowledgeLockerShow:false,
            userName : '',
            isLoggedIn :'',
            userPic : '',
            showMobileMenu : false
        }
    }

	mobileMenu () {
		this.setState({showMobileMenu:!this.state.showMobileMenu});
	}

	NoMenu () {

		return (<div></div>)
	}

	knowledgeLockerShow () {
		if(isLoggedIn) {
			window.location.href = "/myKnowledgeLocker";
		}
		else {
			this.setState({ knowledgeLockerShow: true });
		}
	}

	logout(){
		localStorage.setItem('authToken', '');
		localStorage.setItem('isLoggedIn', JSON.stringify(false));
		localStorage.setItem('userData', []);
		window.location.href = "/";
	}
    componentDidMount(){
        <SocialFacebook/>
    }

    render() {

        return (

            <div className="row">
                <SweetAlert
                    show={this.state.knowledgeLockerShow}
                    title="Wait"
                    type="warning"
                    text="You need to login in order to Access this feature!"
                    onConfirm={() => this.setState({ knowledgeLockerShow: false })}
                />
                <div className="col-md-12 col-sm-12 col-xs-hidden header-main">
                    <div className="col-md-3 col-sm-3">
                        <Link to="/" className="header-nav-item"><img src={logo}/></Link>
                    </div>
                    <div className="col-md-9 col-sm-9">
                        <div className="header-nav">
                            <Link to="/explorecareerpaths" className="header-nav-item">Career Paths</Link>
                            <Link to="/knowledgeblocks" className="header-nav-item">Knowledge Blocks</Link>
                            <Link to="/courses" className="header-nav-item">Micro-Courses</Link>
                            <Link to="/tutorials" className="header-nav-item">Tutorials</Link>
                            <span className="header-nav-item" onClick={()=>this.knowledgeLockerShow()}>MyKnowledgeLocker</span>
                            <SocialFacebook/>

                        </div>
                    </div>
                </div>
                <div className="col-md-hidden col-sm-hidden col-xs-12 mobile_heading">
                    <div className="col-md-4 col-xs-4 mobile_logo">
                        <Link to="/" className="header-nav-item">Knowledge<b>Locker</b></Link>
                    </div>
                    <div className="col-md-8 col-xs-8 mobile_nav">
                        <span id="menu_navigation"><span  className="mobile_hamburger" onClick={() => this.mobileMenu()} >{this.state.showMobileMenu?<i className="fa fa-times" aria-hidden="true"></i>:<i className="fa fa-bars"></i>}</span></span>
                    </div>
                    {this.state.showMobileMenu?<MobileMenuTab
                        userPic={this.state.userPic}
                        userName={this.state.userName}
                        logout = {() => this.logout()}
                        showLocker={() => this.knowledgeLockerShow()}
                    />:this.NoMenu()

                    }
                </div>
            </div>
        );
    }
}

export default Header;