import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Header from './header';
import Footer from './footer';
require('../styles/knowledgeblock.css');

class Pricing extends React.Component {
	constructor(props) {
		super(props);
	} 
   render() {
	   return(
			<div>
				<div className="main_div">
					<Header />
				</div>
				<div className="container">
					
					<br/>
					Most Eucational Resources on this site are free.
					<br/>


					<p> 
					We sell relevant eBooks and Video lesson content at bargain prices for you.

					The prices range from INR (Rs) 100 to Rs 2000.
                    USD $1.99 to $29.99										
					</p>

					<br/>
					

                    <b>	Subscriptions</b>


					<p> Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring
					basis. Subscription charges range from USD $7 per month, with discounted rate at $48 per year.
					</p>
					
					If you have any questions about these Pricing terms, please contact us at team@KnowledgeLocker.com

				</div>
			<Footer />
			</div>
	   )
   }
}

export default Pricing;