import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import BaseUrl from '../config/properties';

class KnolwedgeBlock_Interviews_Practice extends React.Component {
	
	constructor(props) {
	    super(props);
	    this.state = { 
					   interviewsPractiseData:'',
	    			};
	 }

	componentDidMount() {
        axios.get(BaseUrl.base_url+"/api/v1/KnowledgeBlocks/Name/"+this.props.knowledgeBlockName+"/Interviews_Practice_Contents").then((response)=>{
            this.setState({ 
            	interviewsPractiseData: response.data
            });
        });
	}

	render() {
		 return (
		 	<div dangerouslySetInnerHTML={{__html:this.state.interviewsPractiseData}}/>
		 	);
	}
}

export default KnolwedgeBlock_Interviews_Practice;
