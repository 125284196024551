import React from 'react';
import { Button } from "antd";

const QuestionList  = props => {
    const { queCount, quizQuestions, onChange, onSave, examScore, isEvalute, answers  } = props;
    console.log(quizQuestions)
    return (
        <div className='col-sm-12 col-md-12 col-xs-12 mt-3 '>
            {
                isEvalute ?
                    <div
                        className="col-md-12 col-sm-12 col-xs-12 text-right text-primary" style={{fontSize: "20px"}}>Score: {examScore}/{quizQuestions.questions.length}</div>
                    : null
            }
            <div className="card" style={{paddingLeft: "15px", paddingBottom: "15px"}}>{
                isEvalute ?
                    <div className="card-body">
                        <div className="card-text text-muted">
                            {
                                quizQuestions.questions.map((item, i) => (
                                    <div key={i}>
                                        <p className='question evaluate'>
                                            <span>{props.startIndex + i + 1}.</span> 

                                            <div dangerouslySetInnerHTML={{ __html: item.Question }} />

                                            </p>
                                        <ul>
                                            {item && item.Answers && item.Answers.map((ans, j) => (
                                                <li key={j}
                                                    className={`evaluate ${ans.correctAnswer ? 'text-success evaluate' : ans.chooseAnswer ? 'text-error evaluate' : ''}`}>
                                                    <span>{queCount[j]}.</span> {ans.answertext} {ans.correctAnswer ?
                                                    <i className="fa fa-check text-success evaluate"/> : ans.chooseAnswer ?
                                                        <i className="fa fa-times text-error evaluate"/> : ''}</li>
                                            ))}
                                        </ul>
                                        <br/>
                                    </div>
                                ))
                            }
                        </div>
                    </div> :
                    <div className="card-body">
                        <div className="card-text text-muted" style={{padding: "18px"}}>
                            {
                                quizQuestions.questions.map((item, i) => (
                                    <div>
                                        <p style={{color: "#343a40", fontSize: "18px"}}>
                                            <span>{props.startIndex + i + 1}.</span>


                              <div dangerouslySetInnerHTML={{ __html: item.Question }} />

                                            </p> 
                                        {
                                            item && item.Answers && item.Answers.map((ans, j) => (
                                                <div className="row p-0" key={j}>
                                                    <div
                                                        className={`col-md-3 text-left disabled  ${ans.chooseAnswer ? 'text-primary' : ''}`}
                                                        style={{fontSize: 15}}>
                                                        {queCount[j]}.{ans.answertext}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label className="check-container">
                                                            <input type="checkbox" name="correctAnswer"
                                                                   onClick={(e) => onChange(e, i)}
                                                                   checked={ans.chooseAnswer || false} data-id={j}
                                                                   id={`${queCount[j]}`}/>
                                                            <span className="checkmark text-success"/>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-7"/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        <div className="card-body text-center p-5">
                            <div className="mt-4" style={{padding: 10}}>
                                <Button type="primary" onClick={onSave} disabled={quizQuestions.questions.length !== answers.length}>Evaluate</Button>
                            </div>
                        </div>
                    </div>
            }

            </div>
        </div>
    )
}
export default QuestionList
