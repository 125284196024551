
import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Header from './header';
import AdvertisementVertical from './advertical';
import KnowledgeBlockListing from './knowledgeblocklisting';
import Footer from './footer';
require('../styles/knowledgeblock.css');

class KnowledgeBlock extends React.Component {
	constructor(props) {
	    super(props);
	  }

	componentDidMount() {
		ReactDOM.findDOMNode(this).scrollIntoView();
	}
	
	render() {
		var display ={
			display:'block'
		};
		return (
			<div>
				<div className="main_div">
					<Header />
				</div>
				<div className="col-md-12 col-sm-12 col-xs-12 full_div">
					<div className="col-md-9 col-sm-12 col-xs-12">
						<KnowledgeBlockListing display={display}/>
						<div className="mailmunch-forms-widget-620337"></div>
					</div>
					<div className="col-md-3 col-sm-3 col-xs-12 ad_links_vertical">
						<p  className="sponsored_links_vertical">Sponsored Links</p>
						<AdvertisementVertical />
					</div>
				</div>
				<Footer />
			</div>

			)
	}


}

export default KnowledgeBlock;