import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Select } from 'antd';
import MessageDIL from './messageDIL';
import axios from 'axios';
import BaseUrl from '../config/properties';
import SweetAlert from 'sweetalert-react';

require('../styles/dayinlife.css');
require('../styles/sweetalert.css');


var dayInTheLifeArray = [];

class DayInLife extends React.Component {
	
	constructor(props) {
	    super(props);
		console.log(this.props.careerpath);
		this.state = { authToken :'',
					   posted :'',
					   notposted:'',
					   userName : '',
					   userPic : '',
					   postSuccess:false,
					   careerpath:'',
					   list:[],
					   start:1,
					   endReachedStatus:false,
					   resultsCountIsZero:false,
					   emptyField:false
					};
	 }
	
	componentDidMount() {
		let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
		this.setState({list:[]});
		dayInTheLifeArray = [];
		this.getList(1);
		if (isLoggedIn == true)  {
			let authToken = localStorage.getItem('authToken');
			this.setState({authToken : authToken});
			let userData = JSON.parse(localStorage.getItem('userData'));
			 let userPic = userData.picture.data.url;
			 userData = userData.name;
			 this.setState({userName : userData,userPic : userPic});
	   }
	}

	getList(start){
		axios.get(BaseUrl.base_url+"/api/v1/CareerPaths/Name/"+this.props.careerpath+"/DayInTheLifeAccounts?start="+start).then((response)=>{
        	console.log(response.data.dayInTheLifeAccountsList);
			console.log(response.data.endReached);
			if (response.data.resultsCount>0) {
					response.data.dayInTheLifeAccountsList.map((obj)=>{
						dayInTheLifeArray.push(obj)
					})
					this.setState({ 
					list: dayInTheLifeArray,
					endReachedStatus:response.data.endReached
				});
			}
			else {
				this.setState({ 
					resultsCountIsZero:true
				});
			}
        })
	}

	//updatedList() {
		/* this.setState({
			start:this.state.start+4
		}); */
		
		
		//this.getList();
	//}
	
	
	updatedList() {
		this.setState({start: this.state.start + 4}, () => {
        console.log(this.state.start)
		this.getList(this.state.start);
    });
}
	renderResponse(obj,index) {
			var userId = obj.accountCreatedBy_UserId;
			var dp ='https://graph.facebook.com/'+userId+'/picture';
			return(
				<div className="col-md-6 col-sm-6 col-xs-12 resp_Secb" key={index}>
					<div className="resp_Seca">
						<div className="col-md-12 col-sm-12 col-xs-12">
							 <div className="img_respo">
							 {obj.accountCreatedBy_UserId!=null ? <img className="image_shp" src = {dp} /> : <img className="image_shp" src ="https://s3.amazonaws.com/klockerimg/user.png" /> }
								
							 </div>
							 <div className="imgtxt_respo">
								<span className="rep_wrap">{obj.accountCreatedBy_UserName}</span>
								<span className="rep_wrapb">{obj.accountEntryTimestamp}</span>
							 </div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12">
							<p className="text_bloom" dangerouslySetInnerHTML={{__html:obj.dayInTheLifeAccount_Description}} />
						</div>
					</div>
				</div>


			)
		}

	handleClick() {
		console.log(document.getElementById('message').value);
		if(document.getElementById('message').value == null || document.getElementById('message').value == '' || document.getElementById('message').value == 'undefined') {
			this.setState({emptyField : true})
		}else {
			var param = {
			"dayInTheLifeDetails":document.getElementById('message').value.replace(/\n/g, '<br>')
		}
		}
		
		var anonymous = document.getElementById('anonymous').checked;
		if (this.state.authToken && !anonymous) {
			console.log('xxx')	
			axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/CareerPaths/Name/"+this.props.careerpath+"/PostDayInTheLifeAccount",
			  headers: {
				'Content-Type': 'application/json',
				'Authorization':this.state.authToken
			  },
			  data: param
			}).then(response => {
			  console.log(response);
			  dayInTheLifeArray=[];
			 this.setState({start :1,list:[],postSuccess: true });
			  this.getList(1);
			  document.getElementById('message').value='';
			})
			.catch(error => {
			  throw(error);
			});
		}else {
			console.log(param);	
			axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/CareerPaths/Name/"+this.props.careerpath+"/PostDayInTheLifeAccount",
			  headers: {
				'Content-Type': 'application/json',
				//'Authorization':'OU03YXRGeUQ0ampPeWZWbmZtQTlib3JSYk13QlpQeFVEaXFZOXN4QlpRaz0='
			  },
			  data: param
			}).then(response => {
			  console.log(response);
			  dayInTheLifeArray=[];
			  this.setState({start :1,list:[],postSuccess: true });
			  this.getList(1);
			  document.getElementById('message').value='';
			})
			.catch(error => {
			  throw(error);
			});
		}
	}
	buttonDisplay() {
		if(this.state.resultsCountIsZero) {
			return (
				<p className="complete">That's All Folks!!</p>
			)
		}
		else {
			return (
				<button className="rsp_btn" onClick={()=> this.updatedList()}>Show more</button>
			)
		}
	}
	render() {
		return(
			<div className="col-md-12 col-sm-12 col-xs-12 resp_article no-padding-sides">
			<SweetAlert
				show={this.state.postSuccess}
				title="Success"
				type="success"
				text="Successfully added!"
				onConfirm={() => this.setState({ postSuccess: false })}
			/>
			<SweetAlert
				show={this.state.emptyField}
				title="Warning"
				type="warning"
				text="The DayInTheLife comment field cannot be empty"
				onConfirm={() => this.setState({ emptyField: false })}
			/>
				<div className="col-md-12 col-sm-12 col-xs-12 no-padding-sides">
					<div className="col-md-6 col-sm-6 col-xs-12 resp_Secb">
						<div className="resp_Seca">
							<div className="col-md-12 col-sm-12 col-xs-12">
								<span className="resp_articlersp">Enter your "Day In a Life"</span>
								<textarea rows="4" cols="50" placeholder="Message" className="text_respart" id="message"></textarea>
							</div>
							<div className="col-md-12 col-sm-12 col-xs-12">
								<div className="btn_puba"></div>
								  <div className="btn_pubb">
									<span className="ret_check"><input className="chkret" type="checkbox" name="" id="anonymous" />anonymous</span>
									<span className="ret_pub" onClick={() => this.handleClick()}>Share</span>
								  </div>
							</div>
						</div>
					</div>
					<div>
					<div>
						{this.state.list.map((obj,index) => {
							return this.renderResponse(obj,index)
						})}
						
					</div>
				</div>
				</div> 
				<div className="col-sm-12 col-md-12 col-xs-12 button_position">
					{this.buttonDisplay()}
				</div>
			</div>
		)
	}
}

export default DayInLife;

