import React from 'react';
import '../styles/TestDetails.css'
import Header from "./header";
import Footer from "./footer";
import axios from "axios";
import {Spin} from 'antd'
import BaseUrl from "../config/properties";
import { withRouter } from "react-router-dom"




class TutorialsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tutorialsList: [],
            careerPath: "SearchByCareerPath",
            SearchByCareerPath: "All",
            SearchByKeyword: "",
            
            endReached: true,
            startIndex: 1,
            isLoading: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onShowMore = this.onShowMore.bind(this);
        this.onTutorialsList = this.onTutorialsList.bind(this);
    }

    componentWillMount() {
        const {startIndex} = this.state
        this.setState({isLoading: true})
        let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        
        let userData = [];
        
        //if (authToken) {
            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
          //          Authorization: localStorage.getItem('authToken')
                }
            }
            axios.get(BaseUrl.base_url + `/api/v1/Tutorial/getAllTutorials_Paged?start=${startIndex}`, config)
                .then((response) => {
                    if (response.data) {
                        this.setState({
                            tutorialsList: response.data.tutorialResourceList,
                            endReached: response.data.endReached,
                            //startIndex: response.data.startIndex,
                            isLoading: false,
                        })
                    }
                }).catch((error) => {
                this.setState({
                    error: error.message,
                    isLoading: false
                })
            });
        //}
    }

    onTutorialsList() {
       
        const startIndex = 1
        this.setState({isLoading: true})
        //if (authToken) {
            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    //Authorization: localStorage.getItem('authToken')
                }
            }
            axios.get(BaseUrl.base_url + `/api/v1/Tutorial/getAllTutorials_Paged?start=${startIndex}`, config)
                .then((response) => {
                    if (response.data) {
                        console.log("======------Tutorials List------=========>", response.data)
                        this.setState({
                            tutorialsList: response.data.tutorialResourceList,
                            isLoading: false,
                            careerPath: "SearchByCareerPath",
                            SearchByCareerPath: "All",
                            SearchByKeyword: "",
                            endReached: response.data.endReached,
                            startIndex:1

                        })
                    }
                }).catch((error) => {
                this.setState({
                    error: error.message,
                    isLoading: false
                })
            });
        //}
    }

    onSearch() {
        const {SearchByCareerPath, careerPath, SearchByKeyword, startIndex} = this.state
        this.setState({isLoading: true, isSearch: true})
        if (careerPath === "SearchByCareerPath" && !SearchByCareerPath) {
            this.onTutorialsList();
            return;
        }
        //if (authToken) {
            if (careerPath === "SearchByCareerPath") {
                if (SearchByCareerPath === "All") {
                    const config = {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            //Authorization: localStorage.getItem('authToken')
                        }
                    }
                    axios.get(BaseUrl.base_url + `/api/v1/Tutorial/getAllTutorials_Paged?start=${startIndex}`, config)
                        .then((response) => {
                            if (response.data) {
                                this.setState({
                                    tutorialsList: response.data.tutorialResourceList,
                                    endReached: response.data.endReached,
                                    startIndex: response.data.startIndex,
                                    isLoading: false,
                                })
                            }
                        }).catch((error) => {
                        this.setState({
                            error: error.message,
                            isLoading: false
                        })
                    });
                } else {
                    const config = {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                          //  Authorization: localStorage.getItem('authToken')
                        }
                    }
                    axios.get(BaseUrl.base_url + `/api/v1/Tutorial/getTutorialsForCareerPath_Paged/${SearchByCareerPath}?start=${startIndex}`, config)
                        .then((response) => {
                            if (response.data) {
                                this.setState({
                                    tutorialsList: response.data.tutorialResourceList,
                                    endReached: response.data.endReached,
                                    startIndex: response.data.startIndex,
                                    isLoading: false,
                                })
                            }
                        }).catch((error) => {
                        this.setState({
                            error: error.message,
                            isLoading: false
                        })
                    });
                }

            } else {
                const config = {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        //Authorization: localStorage.getItem('authToken')
                    }
                }
                axios.get(BaseUrl.base_url + `/api/v1/Tutorial/SearchByKeywords_Paged?query=${SearchByKeyword}&start=${startIndex}`, config)
                    .then((response) => {
                        if (response.data) {
                            this.setState({
                                tutorialsList: response.data.tutorialResourceList,
                                endReached: response.data.endReached,
                                startIndex: response.data.startIndex,
                                isLoading: false
                            })
                        }
                    }).catch((error) => {
                    this.setState({
                        error: error.message,
                        isLoading: false
                    })
                });

            }

        //}
    }

    onSendData(data) {
        this.props.history.push({
            pathname: `/tutorial/${data.tutorial_Resource_Name_In_Internal_URL}`,
            state: {
                data: data
            }
        })
    }

    onShowMore() {
        const {SearchByCareerPath, careerPath, SearchByKeyword, startIndex, tutorialsList} = this.state
        this.setState({isLoading: true})
        let data = startIndex + 4;
        //if (authToken) {
            if (careerPath === "SearchByCareerPath") {
                if (SearchByCareerPath === "All") {
                    const config = {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            //Authorization: localStorage.getItem('authToken')
                        }
                    }
                    axios.get(BaseUrl.base_url + `/api/v1/Tutorial/getAllTutorials_Paged?start=${data}`, config)
                        .then((response) => {
                            if (response.data) {
                                const data = response.data.tutorialResourceList
                                this.setState({
                                    tutorialsList: [...tutorialsList, ...data],
                                    endReached: response.data.endReached,
                                    startIndex: response.data.startIndex,
                                    isLoading: false,
                                })
                            }
                        }).catch((error) => {
                        this.setState({
                            error: error.message,
                            isLoading: false
                        })
                    });
                }else {
                    const config = {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            //Authorization: localStorage.getItem('authToken')
                        }
                    }
                    axios.get(BaseUrl.base_url + `/api/v1/Tutorial/getTutorialsForCareerPath_Paged/${SearchByCareerPath}?start=${data}`, config)
                        .then((response) => {
                            if (response.data) {
                                const data = response.data.tutorialResourceList
                                this.setState({
                                    tutorialsList: [...tutorialsList, ...data],
                                    endReached: response.data.endReached,
                                    startIndex: response.data.startIndex,
                                    isLoading: false
                                })
                            }
                        }).catch((error) => {
                        this.setState({
                            error: error.message,
                            isLoading: false
                        })
                    });
                }

            } else {
                const config = {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        //Authorization: localStorage.getItem('authToken')
                    }
                }
                axios.get(BaseUrl.base_url + `/api/v1/Tutorial/SearchByKeywords_Paged?query=${SearchByKeyword}&start=${data}`, config)
                    .then((response) => {
                        if (response.data) {
                            const data = response.data.tutorialResourceList
                            this.setState({
                                tutorialsList: [...tutorialsList, ...data],
                                endReached: response.data.endReached,
                                startIndex: response.data.startIndex,
                                isLoading: false
                            })
                        }
                    }).catch((error) => {
                    this.setState({
                        error: error.message,
                        isLoading: false
                    })
                });

            }

        //}
    }


    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
            startIndex: 1
        })
    }

    render() {
        const {tutorialsList, careerPath, SearchByCareerPath, SearchByKeyword, endReached, isLoading} = this.state
        return (
            <div>
                <div className="main_div">
                    <Header/>
                </div>
                <div className="container quiz-body">
                    <div className="col-md-12 col-sm-12 col-xs-12 article_content">
                        <div className="col-sm-3 other">
                            <h2 className="article_innercont">Tutorials List</h2>
                        </div>
                        <div className="col-sm-9 pull-right ">
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <select className="form-control" style={{width: "auto"}} name="careerPath"
                                            onChange={this.onChange} value={careerPath}>
                                        <option value="SearchByCareerPath">Search by Career Path</option>
                                        <option value="SearchByKeyword">Search by Keyword</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                {
                                    careerPath === "SearchByCareerPath" ?
                                        <div className="form-group">
                                            <select className="form-control" style={{width: "auto"}}
                                                    name="SearchByCareerPath"
                                                    onChange={this.onChange} value={SearchByCareerPath}>
                                                <option value="All">
                                                    All
                                                </option>
                                                <option value="Software Development Engineering">Software
                                                    Development Engineering
                                                </option>
                                                <option value="Software Test Engineering">Software Test
                                                    Engineering
                                                </option>
                                                <option value="Mechanical Engineering">Mechanical
                                                    Engineering
                                                </option>
                                            </select>
                                        </div> :
                                        <div className="form-group">
                                            <input type="text" className="form-control inputText"
                                                   name="SearchByKeyword" value={SearchByKeyword}
                                                   onChange={this.onChange}/>
                                        </div>
                                }
                            </div>
                            <div className="col-sm-1">
                                <input type="button" value="Search" style={{marginLeft: "-50px"}}
                                       className="btn btn-primary inputText" onClick={this.onSearch}/>
                            </div>
                            <div className="col-sm-1">
                                <input type="button" value="Clear Search" style={{marginLeft: "-38px"}}
                                       className="btn btn-primary inputText" onClick={this.onTutorialsList}/>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12 article_content">
                            {isLoading ?
                                <div style={{marginTop: "10%", marginLeft: "46%"}}><Spin size="large" tip="Loading..."/>
                                </div> :
                                tutorialsList && tutorialsList.length ?
                                    <table className="table table-bordered table-hover table-responsive ">
                                        <thead>
                                        <tr style={{fontSize: "15px", fontWeight: 300, color: "black"}}>
                                            <td>Title</td>
                                            <td>Resource Description</td>
                                            <td>Resource Tags</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            tutorialsList && tutorialsList.length ?
                                                tutorialsList.map((item, i) => (
                                                    <tr key={i}>
                                                        <td><a
                                                            onClick={() => this.onSendData(item)}>{item.tutorial_Title}</a>
                                                        </td>
                                                        <td>{item.tutorialResourceDescription}</td>
                                                        <td>{item.tutorialResourceTags}</td>
                                                    </tr>
                                                )) : null
                                        }
                                        </tbody>
                                    </table> : <div className="text-center">
                                        <h2 className="article_innercont"> NO data found </h2>
                                    </div>
                            }
                        </div>
                        {endReached === false ?
                            <div className="col-md-12 col-sm-12 col-xs-12 article_content">
                                <div className="col-md-4"/>
                                <div className="col-md-4 text-center" style={{marginBottom: "10px"}}>
                                    <input type="button" value="More Results" onClick={this.onShowMore}
                                           className="btn btn-primary inputText"/>
                                </div>
                                <div className="col-md-4"/>
                            </div> : null
                        }
                    </div>
                    <Footer/>
                </div>
            </div>
        );

    }
}

export default withRouter(TutorialsList);
