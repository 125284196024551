import React from 'react';
import SocialLogin from "../facebook";

function LogoutButton(props) {
    console.log("PROPS LOGOUT", props.triggerLogout)
    return(
            <span onClick={props.triggerLogout} ><i className="fa fa-sign-out"></i> Sign out</span>
        )
}

export default SocialLogin(LogoutButton);