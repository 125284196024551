import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import BaseUrl from '../config/properties';
class TrainingsAndMeetups extends React.Component {
	
	constructor(props) {
	    super(props);
	    this.state = { 
					   getStartedData:'',
	    			};
	 }

	componentDidMount() {
        axios.get(BaseUrl.base_url+"/api/v1/KnowledgeBlocks/Name/"+this.props.knowledgeBlockName+"/Trainings_Meetups_Contents").then((response)=>{
        	//console.log(response.data);
            this.setState({ 
            	getStartedData: response.data
            });
		});
	}

	render() {
		 return (
		 	<div dangerouslySetInnerHTML={{__html:this.state.getStartedData}}/>
		 	);
	}
}

export default TrainingsAndMeetups;
