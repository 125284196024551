import LogoutButton from "./logoutButton";
import React from "react";

export function ProfileTab (props){
    return(<li className="dropdown user_menu">
        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
            {props.user.name}
            <img src={props.user.profilePicURL} className="profile-image img-circle"/></a>
        <ul className="dropdown-menu">
            <li> <LogoutButton getInstance={props.getInstance} {...props}/> </li>
        </ul>
    </li>)
}