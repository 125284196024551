
import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Header from './header';
import CareerPathListing from './careerpathlisting';
import AdvertisementVertical from './advertical';
import Footer from './footer';
require('../styles/knowledgeblock.css');

class ExploreCareerPath extends React.Component {
	constructor() {
	    super();
	  }

	render() {
		return (
			<div>
				<div className="main_div">
					<Header />
				</div>
				<div className="col-md-12 col-sm-12 col-xs-12 full_div">
					<div className="col-md-9 col-sm-9 col-xs-12 kb_title">
						<p  className="kb_title_p">Build Skills for the career you want</p>
						<CareerPathListing />
						<div className="mailmunch-forms-widget-620337"></div>
					</div>
					<div className="col-md-3 col-sm-3 col-xs-12 ad_links_vertical">
						<p  className="sponsored_links_vertical">Sponsored Links</p>
						<AdvertisementVertical />
					</div>
				</div>
				<Footer />
			</div>
			)
	}


}

export default ExploreCareerPath;