import React from "react";


import HomePage from "./components/homepage";
import Header from "./components/header";
import Header1 from "./components/header1";
import KnowledgeBlock from "./components/knowledgeblock";
import ExploreCareerPath from "./components/explorecareerpath";
import CareerDetails from "./components/careerdetails";
import KnowledgeBlockDetails from "./components/knowledgeblockdetails";
import VideosAndBlogs from "./components/videosandblogs";
import Privacy from "./components/privacy";
import Terms from "./components/terms";
import UserDataDelation from "./components/userdatadelation";
import Pricing from "./components/pricing";
import Contact from "./components/contact";
import Prettify from "./components/prettify";
import Article from "./components/article";
import Tutorial from "./components/tutorial";
import Courses from "./components/Courses";
import Quiz from "./components/Quiz";
import AboutUs from "./components/about";
import MyKnowledgeLocker from "./components/Knowledgelocker";
import QuizDetails from "./components/QuizeDetails";
import TutorialsList from "./components/tutorialsList";
import courseIntro from "./components/courseIntro";
import courseHome from "./components/courseHome";
import SocialFacebook from "./FBcontainers/socialFacebook";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
    return (

        <Router>
                <Switch>
                    <Route path="/header" component={Header} />
                    <Route path="/header1" component={Header1} />
                    <Route path="/knowledgeblocks" component={KnowledgeBlock} />
                    <Route path="/explorecareerpaths" component={ExploreCareerPath} />
                    <Route path="/careerdetails/:careerpath_url/:active_tab" component={CareerDetails} />
                    <Route path="/knowledgeblockdetails/:knowledgeblock_url/:active_tab" component={KnowledgeBlockDetails} />
                    <Route path="/videosandblogs" component={VideosAndBlogs} />
                    <Route path="/AboutUs" component={AboutUs} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/userdatadeletion" component={UserDataDelation} />
                    <Route path="/pricing" component={Pricing} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/prettify" component={Prettify} />
                    <Route path="/article/:articleName" component={Article} />
                    <Route path="/tutorial/:tutorialName" component={Tutorial} />
                    <Route path="/myKnowledgeLocker" component={MyKnowledgeLocker} />
                    <Route path="/tutorials" component={TutorialsList} />
                    <Route path="/courses" component={Courses} />
                    <Route path="/course/:name" component={courseIntro} />
                    <Route path="/course/:name/Lesson" component={courseHome} />
                    <Route path="/course/:name/Lesson/:id" component={courseIntro} />
                    <Route path="/quizzes" component={Quiz} />
                    <Route path="/quiz/:name" component={QuizDetails} />
                    <Route path="/" component={HomePage} />
                </Switch>
        </Router>
    );
}
export default App;
