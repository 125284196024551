import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import axios from 'axios';
import BaseUrl from '../config/properties';
import '../styles/careerpathlist.css';



class CareerPathList extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = { data:[] };
	}
	componentDidMount() {
		axios.get(BaseUrl.base_url+'/api/v1/CareerPaths/getAllCareerPaths').then((response)=> {
			console.log(response.data.careerPaths);
			this.setState({ 
            	data: response.data.careerPaths
			})
		});
	}

	renderList(obj,i) {
    var background = {
	width:'100%',
	backgroundImage : 'url('+obj.careerPathImageLink+')',
	backgroundRepeat  : 'no-repeat',
	height :"226px",
	backgroundSize : '100% 100%'
	};
    console.log('index id --->',i);
	if(i<2) {
	return (
			<div className="col-md-6 col-sm-6 col-xs-12" key={obj.careerPathId}>       
				<div className="careerpath_block">
					<Link to={"/careerdetails/"+obj.careerPathName_UrlEncoded+"/career_chart_and_job_roles"} ><div style ={ background } >
						<div className ="overlay">
							<div className = "home-content">
								<p className="career_title">{obj.careerPathName}</p>
								<p className="career_info">{obj.video_Article_ResourceCount} resources available</p>
							</div>
						</div>
					</div></Link>
				</div>
			</div>
        )
	}else{
		return (
			<div className="col-md-4 col-sm-4 col-xs-12" key={obj.careerPathId}>       
				<div className="careerpath_block">
					<Link to={"/careerdetails/"+obj.careerPathName_UrlEncoded+"/career_chart_and_job_roles"} ><div style ={ background } >
						<div className ="overlay">
							<div className = "home-content">
								<p className="career_title">{obj.careerPathName}</p>
								<p className="career_info">{obj.video_Article_ResourceCount} resources available</p>
							</div>
						</div>
					</div></Link>
				</div>
			</div>
			)
		}
	}	
	render() {
		if (this.state.data.length>0) {
        	return(
			<div>
				{
					this.state.data.map((obj,i) => {
						if(i<this.props.limit) {
							// console.log('path ---->',this.renderList(obj))
							return this.renderList(obj,i)
						}
					})
				}
			</div>
        	)
        }else{
        	return(<p>No found</p>);
        }
	}
}

export default CareerPathList;
