import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import axios from 'axios';
import BaseUrl from '../config/properties';
import SweetAlert from 'sweetalert-react';
import { Select } from 'antd';
var Filter = require('../helper/filterknowledgeblock');
const Option = Select.Option;

require('../styles/knowledgeblocklisting.css');
require('../styles/sweetalert.css');
//const rss = require('../images/rss.png');
const rss = 'https://s3.amazonaws.com/klockerimg/rss.png';
const followed = "https://s3.amazonaws.com/klockerimg/follow-blue.png";
const tick ="https://s3.amazonaws.com/klockerimg/tick.png";
const videosImage = "https://s3.amazonaws.com/klockerimg/play-button-1-copy-2.png";

function FollowedImage(props) {
		return(
			<div>
				<img src ={ followed } className="following_image cursor" onClick={props.followingIt} />
				<span className="block_activity_unfollow cursor" onClick={props.followingIt}>&nbsp;Unfollow</span>
			</div>
		)
	}

function FollowImage(props) {
		return(
			<div>
				<img src ={ rss } className="cursor" onClick={props.followIt} />
				<span className="block_activity cursor" onClick={props.followIt}>&nbsp;Follow</span>
			</div>
		)
	}

class KnowledgeBlocksListing extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = { data: [],
					   dataKB: [],
	    			   authToken :'',
	    			   followItSuccess:false,
	    			   followItWait:false,
					   unfollowSuccess:false,
	    			   resultsCount:0
	    			};
	  }

	componentDidMount() {
		var isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
		var authToken = null;

		if (isLoggedIn == true)  {
			authToken = localStorage.getItem('authToken');

			this.setState({authToken : authToken}, () => {
				this.getAllData();
			});
		}else{
			this.getAllData();
		}
	   }
	    getAllData() {
			/* console.log('ssssssssssssssssss',authToken); */
			if (this.state.authToken) {
				axios({
				  method: 'get',
				  url: BaseUrl.base_url+"/api/v1/KnowledgeBlocks/getAllTopKnowledgeBlocksForUser",
				  headers: {
					'Authorization':this.state.authToken
				  },
				}).then(response => {

					let newDAta=[];
					this.setState({
						data: response.data.knowledgeBlocks,
						dataKB: response.data.knowledgeBlocks,
						resultsCount:response.data.resultsCount
					});
				})
				.catch(error => {
				  throw(error);
				});
			}
			else {
				axios.get(BaseUrl.base_url+"/api/v1/KnowledgeBlocks/getAllTopKnowledgeBlocksForUser").then((response)=>{

					let newDAta=[];
					this.setState({
						data: response.data.knowledgeBlocks,
						dataKB: response.data.knowledgeBlocks,
						resultsCount:response.data.resultsCount
					});
				});
			}
		}


	handleChange(value) {
		console.log(value);
		var filterValue = value;

		if(filterValue == 'All') {
			console.log('all in')
			this.getAllData();
		}else {
			var arrOfObj = {data: this.state.data};
			this.filter(filterValue, arrOfObj, this);
		}
	}

	filter(filterValue, arrOfObj, props){
		Filter.filterObject(filterValue, arrOfObj, function(err,result){
            console.log({after:result,isTrue:true});
            props.setState({
                dataKB: result.data,
				resultsCount:result.data.length
            });
        });
	}
	callback(key) {
		console.log(key);
	}

	followIt(knowledgeBlockId,value){
		var param = {
				"KnowledgeBlockId":knowledgeBlockId,
				"OptionValue":value
			}
		if (this.state.authToken) {
			axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/KnowledgeBlock/FollowUnFollowKnowledgeBlock",
			  headers: {
				'Content-Type': 'application/json',
				'Authorization':this.state.authToken
			  },
				data: param
				}).then(response => {
					console.log(response)
					if(response.data.success == "Following knowledge block"){
						this.setState({ followItSuccess: true });
						this.getAllData();
					}
					else if(response.data.success == "UnFollowed knowledge block") {
						this.setState({ unfollowSuccess: true });
						this.getAllData();
					}
				})
				.catch(error => {
				  throw(error);
				});
			}else{
				this.setState({ followItWait: true });
			}

	}

	searchFun(){
		var searchKey = document.getElementById('searchKey').value;
		if(searchKey==null || searchKey == '') {
			this.getAllData();
		}
		else {
			axios.get(BaseUrl.base_url+"/api/v1/KnowledgeBlocks/SearchByKeywords?query="+searchKey).then((response)=>{
				console.log(response.data.knowledgeBlocks);
				this.setState({
					dataKB: response.data.knowledgeBlocks,
					resultsCount:response.data.resultsCount
				});
			});
		}
	}

    renderList(obj) {
    var background = {backgroundImage : "url(" + obj.knowledgeBlock_ImageURL + ")",
					  backgroundRepeat  : 'no-repeat',
					  backgroundSize : '100% 100%',
					  width:"100%",
					  height:"254px"
					};
		return (
			<div key={obj.knowledgeBlock_Id}>
				<div className="col-md-4 col-sm-6 col-xs-12 extra-padding right-padding-zero">
	            <div className="block">
	            	<div className=" col-md-12 col-sm-12 col-xs-12 block_image">
	            		<Link to={"/knowledgeblockdetails/"+obj.knowledgeBlock_Name_UrlEncoded+"/get_started"}><div style= { background } ></div></Link>
	            	</div>
	            	<div className ="block_content">
	            		<div className="sub_content">
		            		<Link to={"/knowledgeblockdetails/"+obj.knowledgeBlock_Name_UrlEncoded+"/get_started"}><p className="block_title">{obj.knowledgeBlock_Name}</p></Link>
		            		{
		            			/*
		            			removed the "onClick={() =>this.detailKnowledgeBlock() }" as it was reloading the page.
		            			*/
		            		}

		            		<Link to={"/knowledgeblockdetails/"+obj.knowledgeBlock_Name_UrlEncoded+"/get_started"}><p className="block_description">{obj.knowledgeBlock_Description}</p></Link>
	            		</div>
	            		<div>
	            			<div className="block_notifications follow_block">
								{obj.isCurrentUserFollowing?<FollowedImage
															 followingIt ={() => this.followIt(obj.knowledgeBlock_Id,0)}
															 />:
															 <FollowImage
															 followIt = {() => this.followIt(obj.knowledgeBlock_Id,1)}
															 />
								}
	            			</div>
	            			<div className="block_notifications videos_block">
	            				<img src = {videosImage} /><span className="block_activity ">&nbsp;{obj.knowledgeBlock_ResourceCount} Videos</span>
	            			</div>
	            			<div className="block_notifications tick_block">
	            				<img src = {tick} className="tick" /><span className="block_activity">Interviewpreps</span>
	            			</div>
	            		</div>
	            	</div>
	            </div>
	        </div>
			</div>

		)
	}

	renderThis(){
		if (this.state.dataKB) {
        	return(
        		this.state.dataKB.map((obj) => {
        			return this.renderList(obj)
        		})
        		)
        }else{
        	return(<p>Not found</p>);
        }
	}

	render() {
        return (
        	<div>
        		<SweetAlert
				show={this.state.followItWait}
				title="Wait"
				type="warning"
				text="You need to login in order to follow"
				onConfirm={() => this.setState({ followItWait: false })}
				/>
				<SweetAlert
				show={this.state.followItSuccess}
				title="Success"
				type="success"
				text="You are following this knowledge block"
				onConfirm={() => this.setState({ followItSuccess: false })}
				/>
				<SweetAlert
				show={this.state.unfollowSuccess}
				title="Success"
				type="success"
				text="You have unfollowed this knowledge block successfully"
				onConfirm={() => this.setState({ unfollowSuccess: false })}
				/>

				<div className="col-md-12 kb_title" style={this.props.display}>
					<p  className="kb_title_p">KnowledgeBlocks</p>
					<div className="search-container">
						<form action='javascript:void(0)' onSubmit={()=>this.searchFun()}><input className='search-box' id="searchKey" type='text' placeholder= 'Search for a course by name' /><button type='submit' className="search-button" ><i className="fa fa-search" aria-hidden="true"></i></button></form>
					</div>

					<div className="col-md-12 extra-padding filterKB" >
						<label>Filter:&nbsp;&nbsp;&nbsp;</label>
						<Select
							showSearch
							style={{ width: 200 }}
							placeholder="Select preferred CareerPath"
							optionFilterProp="children"
							onChange={this.handleChange.bind(this)}
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						>
						<Option value="All">All</Option>
						<Option value="Software Test Engineering">Software Test Engineering</Option>
						<Option value="Software Development Engineering">Software Development Engineering</Option>
						<Option value="Software Operations Engineering ">Software Operations Engineering </Option>
					  </Select>
					</div>
					<p className="kb_info_listing">Showing {this.state.resultsCount} KnowledgeBlocks</p>
				</div>

        		{this.renderThis()}

        	</div>
        )
    }
}

export default KnowledgeBlocksListing;