import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import axios from 'axios';
import BaseUrl from '../config/properties';
import Header from './header';
import Advertisement from './ad';
import KnowledgeBlockListing from './knowledgeblocklisting';
import { Progress } from 'reactstrap';
import VideosAndBlogs from './videosandblogs';
import CareerChart from './careerchart';
import DayInLife from './dayinlife';
import InterviewAndPractices from './interviewandpractices';
import CareerKnowledgeBlock from './careerknowledgeblock';
import Footer from './footer';
import SweetAlert from 'sweetalert-react';
import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;
var star = {
	backgroundImage : "url(https://s3.amazonaws.com/klockerimg/shape.png)",
	backgroundRepeat  : 'no-repeat',
	width:"100%",
	height:"100%"
};

const rss = 'https://s3.amazonaws.com/klockerimg/rss_white.png';
const followed = "https://s3.amazonaws.com/klockerimg/unfollow-careerpath.png"
require('../styles/antd.css');
require('../styles/careerpath.css');
function FollowedImage(props) {
		return(
			<div>
				<button className="cp_following" onClick={props.followingIt} ><img src ={ followed } className="following_image"/>&nbsp;Unfollow</button>
			</div>
		)
	}
	
function FollowImage(props) {
		return(
			<div>
				<button className="cp_follow" onClick={props.followIt} ><img src ={ rss } />&nbsp;Follow</button>
			</div>
		)
	}
class CareerDetails extends React.Component {
	
	constructor(props) {
	    super(props);
		this.state = { 
			data:[],
			authToken :'',
			followItSuccess:false,
			followItWait:false,
			unfollowSuccess:false};
		//console.log(this.props.params.careerpath_url);
		console.log(this.props)
	 }
	componentDidMount() {
		ReactDOM.findDOMNode(this).scrollIntoView();
		let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
		if (isLoggedIn == true)  {
			let authToken = localStorage.getItem('authToken');
			this.setState({authToken : authToken}, () => {
				this.getAllData();
			});
		}
		else {
			this.getAllData();
		}
	}
	
	runCodePrettify() {
      console.log({isCalled:'yes'})
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js';
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    }

	getAllData () {
		console.log(this.state.authToken)
		if(this.state.authToken) {
			axios({
				method : 'get',
				url : BaseUrl.base_url+"/api/v1/CareerPaths/Name/"+this.props.match.params.careerpath_url+"/GeneralDetails",
				headers : {
					'Authorization':this.state.authToken,
				},
			}).then((response)=>{
				console.log(response.data);
				this.setState({ 
					data: response.data,
					opportunity : response.data.opportunity_Score,
					career_growth : response.data.careerGrowth_Score,
					professional_community : response.data.professionalCommunity_Score
            });
        })
		}
		else {
		axios.get(BaseUrl.base_url+"/api/v1/CareerPaths/Name/"+this.props.match.params.careerpath_url+"/GeneralDetails").then((response)=>{
        	console.log(response.data);
            this.setState({ 
            	data: response.data,
				opportunity : response.data.opportunity_Score,
				career_growth : response.data.careerGrowth_Score,
				professional_community : response.data.professionalCommunity_Score
            });
        })
	}
}

	
	/* followIt(careerPathId){
		console.log(this.props);
		// this.props.location.pathname="/careerdetails/Software+Development+Engineering/a_day_in_a_life";
		// this.props.params.active_tab='a_day_in_a_life';
		//this.props.history.push('/careerdetails/Software+Development+Engineering/a_day_in_a_life');
		if (this.state.authToken) {
			axios.post(BaseUrl.base_url+"/view/api/CareerPath/FollowCareerPath?UserToken="+this.state.authToken+"&CareerPathId="+careerPathId).then((response)=>{
			      this.setState({ followItSuccess: true });
			});
		}else{
		      this.setState({ followItWait: true });
		}
	} */
	
	followIt(careerPathId,value){
		var param = {
				"CareerPathId":careerPathId,
				"OptionValue":value
			}
		if (this.state.authToken) {
			axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/CareerPath/FollowUnFollowCareerPath",
			  headers: {
				'Content-Type': 'application/json',
				'Authorization':this.state.authToken
			  },
				data: param
				}).then(response => {
					console.log(response)
					if(response.data.success == "Following Career Path"){
						this.setState({ followItSuccess: true });
						this.getAllData();
					}
					else if(response.data.success == "UnFollowed Career Path") {
						this.setState({ unfollowSuccess: true });
						this.getAllData();
					}
				})
				.catch(error => {
				  throw(error);
				});
			}else{
				this.setState({ followItWait: true });
			}
			
			/* axios.post(BaseUrl.base_url+"/view/api/KnowledgeBlock/FollowKnowledgeBlock?UserToken="+this.state.authToken+"&KnowledgeBlockId="+knowledgeBlockId).then((response)=>{
				this.setState({ followItSuccess: true });
			});
		}else{
		      this.setState({ followItWait: true });
		} */
	}

	handleModeChange(key){
		this.props.history.push('/careerdetails/'+this.props.match.params.careerpath_url+'/'+key);
	}
	
	 render() {
		 this.runCodePrettify();
	 	console.log({careerData:this.state.careerData,careerVideo:this.state.careerVideo,careerArticle:this.state.careerArticle});
		// function callback(val,this) {
		// 		console.log(val);
		// 		// this.props.history.push('/careerdetails/'+this.props.params.careerpath_url+'/'+val);
		// 	}
		var opportunity = this.state.opportunity*10;
		var career_growth = this.state.career_growth*10;
		var professional_community = this.state.professional_community*10;
		var display ={
	    display:'none'
		};
		 return (		
		 <div>
			<SweetAlert
				show={this.state.followItWait}
				title="Wait"
				type="warning"
				text="You need to login in order to follow"
				onConfirm={() => this.setState({ followItWait: false })}
				/>
				<SweetAlert
				show={this.state.followItSuccess}
				title="Success"
				type="success"
				text="You are following this career path"
				onConfirm={() => this.setState({ followItSuccess: false })}
				/>
				<SweetAlert
				show={this.state.unfollowSuccess}
				title="Success"
				type="success"
				text="You have unfollowed this career path successfully"
				onConfirm={() => this.setState({ unfollowSuccess: false })}
				/>
			<div className="main_div">
	            <Header />
			</div>
			<div className="col-md-12 col-sm-12 col-xs-12 cp_page">
				<div className="container">
					<div className="col-md-6 col-sm-7 col-xs-12 no-left">
						<p className="cp_title">{this.state.data.careerPathName}</p>
						<p className="cp_para">{this.state.data.careerPathDescription}</p>
						{this.state.data.isCurrentUserFollowing?<FollowedImage
															 followingIt ={() => this.followIt(this.state.data.careerPathId,0)}
															 />:
															 <FollowImage
															 followIt = {() => this.followIt(this.state.data.careerPathId,1)}
															 />	 
								}
					</div>
					<div className="col-md-4 col-sm-5 col-xs-12 cp_att no-right">
						<div className="cd_stats">
							<div className="col-md-12 col-sm-12 col-xs-12 cp_rating_div">
								<div className="col-md-4 col-sm-5 col-xs-12 cp_star">
									<div className="star" style= { star }>
										<p className="cp_rating">{this.state.data.netScore}</p>
									</div>
								</div>
								<div className="col-md-8 col-sm-7 col-xs-12 cp_promise">
									<p>{this.state.data.netScore_SummaryText}</p>
								</div>
							</div>
							<div className="col-md-12 col-sm-12 col-xs-12">
								<div className="cp_attributes_desc">
									<div className="cp_attributes">
										<p>Opportunity</p>
									</div>
									<p className="cp_attributes_ratings">{this.state.data.opportunity_Score} / 10</p>
								</div>
								<div className="progressbar">
									<Progress value={opportunity} height="10px"/>
								</div>
							</div>
							<div className="col-md-12 col-sm-12 col-xs-12">
								<div className="cp_attributes_desc">
									<div className="cp_attributes">
										<p>Career Growth</p>
									</div>
									<p className="cp_attributes_ratings">{this.state.data.careerGrowth_Score} / 10</p>
								</div>
								<div className="progressbar">
									<Progress value={career_growth} height="10px"/>
								</div>
							</div>
							<div className="col-md-12 col-sm-12 col-xs-12">
								<div className="cp_attributes_desc">
									<div className="cp_attributes">
										<p>Professional Community</p>
									</div>
									<p className="cp_attributes_ratings">{this.state.data.professionalCommunity_Score} / 10</p>
								</div>
								<div className="progressbar">
									<Progress value={professional_community} height="10px"/>
								</div>
							</div>
						</div>
					</div>
				</div>	
			</div>
			<div className="col-md-12 col-sm-12 col-xs-12 extra-top">
				<div className="container">
					<p className="cp_about">About {this.state.data.careerPathName}</p>
					<p className="cp_about_desc">{this.state.data.careerPathSummary}</p>
					<div className="col-md-12 col-sm-12 col-xs-12 cp_tabs knowledgeblock_content_text">
						<Tabs onChange={this.handleModeChange.bind(this)} defaultActiveKey={this.props.match.params.active_tab}>
							<TabPane tab="Career Chart & Job Roles"  key="career_chart_and_job_roles"><CareerChart careerpath={this.props.match.params.careerpath_url} /></TabPane>
							<TabPane tab="Videos and Blogs" key="videos_and_blogs"><VideosAndBlogs careerpath={this.props.match.params.careerpath_url} /></TabPane>
							<TabPane tab="Interviews & Practice Assignments" key="interviews_practice_assignments"><InterviewAndPractices careerpath={this.props.match.params.careerpath_url}/></TabPane>
							<TabPane tab="A Day in a Life" key="a_day_in_a_life"><DayInLife careerpath={this.props.match.params.careerpath_url}/></TabPane>
							<TabPane tab="Knowledge Blocks" key="knowledge_blocks"><CareerKnowledgeBlock careerpath={this.props.match.params.careerpath_url}/></TabPane>
						</Tabs>
					</div>
				</div>
			</div>
			<div className="col-md-12 col-sm-12 col-xs-12 extra">
				<div className="container ad_bg">
					<p className="ad_header">Sponsored Links</p>
					<div className="col-md-12 col-sm-12 col-xs-12 no-padding">
						<Advertisement />
					</div>
				</div>
			</div>
			<div className="col-md-12 col-sm-12 col-xs-12 extra-top">
				<div className="container">
					<div className = "fillup">
						<div className="col-md-12 col-sm-12 col-xs-12 knowledgeblock_title">
							<p className="knowledgeblock_title_p">Popular Knowledge Blocks</p>
							<div className="view_all"><Link to="/knowledgeblock/" className="view_all_style">View All</Link></div>
						</div>
						<KnowledgeBlockListing display={display} />
					</div>
				</div>
			</div>
			<Footer /> 
		</div>
		 )
	 }
	  
}

export default CareerDetails;
