import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Select } from 'antd';
import MessageDIL from './messageDIL';
import axios from 'axios';
import BaseUrl from '../config/properties';
import SweetAlert from 'sweetalert-react';

require('../styles/dayinlife.css');
require('../styles/sweetalert.css');

var comments = [];

class TutorialComments extends React.Component {
	
	constructor(props) {
		console.log({comments:comments})
	    super(props);
		console.log(this.props.tutorialUrl);
		this.state = { authToken :'',
					   posted :'',
					   notposted:'',
					   userName : '',
					   userPic : '',
					   postSuccess:false,
					   tutorialUrl:'',
					   list:[],
					   endReachedStatus:false,
					   start:1,
					   resultsCountIsZero:false
					};
	 }
	
	componentDidMount() {
		let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
		this.setState({list:[]});
		comments = [];
		console.log(this.state.start);
		this.getList(1);
		if (isLoggedIn == true)  {
			let authToken = localStorage.getItem('authToken');
			this.setState({authToken : authToken});
			let userData = JSON.parse(localStorage.getItem('userData'));
			 let userPic = userData.picture.data.url;
			 userData = userData.name;
			 this.setState({userName : userData,userPic : userPic});
	   }
	}

	getList(start){
		//console.log('this'+ this.state.start)
		
		axios.get(BaseUrl.base_url+"/api/v1/Tutorial/Name/"+this.props.tutorialUrl+"/getComments?start="+start).then((response)=>{
        	console.log(response.data.tutorialCommentsList);
			if (response.data.resultsCount>0) {
				response.data.tutorialCommentsList.map((obj)=>{
						comments.push(obj)
					})
				this.setState({ 
					list: comments,
					endReachedStatus:response.data.endReached
				});
				console.log(this.state.list)
			}
			else {
				this.setState({ 
					resultsCountIsZero:true
				});
			}
		})
	}
	
	updatedList() {
		this.setState({start: this.state.start + 4}, () => {
			console.log(this.state.start)
			this.getList(this.state.start);
		});
		
	}

	renderResponse(obj,index) {
			var userId = obj.commentCreatedBy_UserId;
			var dp ='https://graph.facebook.com/'+userId+'/picture';
			return(
				<div className="col-md-6 col-sm-6 col-xs-12 resp_Secb" key={index}>
					<div className="resp_Seca">
						<div className="col-md-12 col-sm-12 col-xs-12">
							 <div className="img_respo">
							 {obj.commentCreatedBy_UserId!=null ? <img className="image_shp" src = {dp} /> : <img className="image_shp" src ="https://s3.amazonaws.com/klockerimg/user.png" /> }
								 {/*<img className="image_shp" src ="http://54.82.248.231/knowledge-locker/client/images/user.png" />*/}
							 </div>
							 <div className="imgtxt_respo">
								<span className="rep_wrap">{obj.commentCreatedBy_UserName}</span>
								<span className="rep_wrapb">{obj.commentEntryTimestamp}</span>
							 </div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12">
							<p className="text_bloom" dangerouslySetInnerHTML={{__html:obj.comment_Text}} />
						</div>
					</div>
				</div>


			)
		}

	handleClick() {
		var param = {
		"commentText":document.getElementById('message').value.replace(/\n/g, '<br>')
		}
		console.log(param)
		var anonymous = document.getElementById('anon').value;
		
		if (this.state.authToken!='' && anonymous!='anonymous') {
			console.log(param)	
			axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/Tutorial/Name/"+this.props.tutorialUrl+"/addComment",
			  headers: {
				'Content-Type': 'application/json',
				'Authorization':this.state.authToken
			  },
			  data: param
			}).then(response => {
			  console.log(response);
			  comments=[];
			  this.setState({start :1,list:[],postSuccess: true });
			  this.getList(1);
			  document.getElementById('message').value='';
			})
			.catch(error => {
			  throw(error);
			});
		}else {
			console.log(param);	
			axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/Tutorial/Name/"+this.props.tutorialUrl+"/addComment",
			  headers: {
				'Content-Type': 'application/json',
				//'Authorization':this.state.authToken
			  },
			  data: param
			}).then(response => {
			  console.log(response);
			  comments=[];
			  this.setState({start :1,list:[],postSuccess: true });
			  this.getList(1);
			  document.getElementById('message').value='';
			})
			.catch(error => {
			  throw(error);
			});
		}
	}
	
	buttonDisplay() {
		if(this.state.resultsCountIsZero) {
			return (
				<p className="complete">That's All Comments we have for this Tutorial, folks!!! Keep the great comments coming.</p>
			)
		}
		else {
			return (
				<button className="rsp_btn" onClick={()=> this.updatedList()}>Show More Comments</button>
			)
		}
	}
	
	render() {
		console.log({comments:comments})
		return(
			<div className="col-md-12 col-sm-12 col-xs-12 resp_tutorial no-padding-sides">
			<SweetAlert
				show={this.state.postSuccess}
				title="Success"
				type="success"
				text="Successfully added!"
				onConfirm={() => this.setState({ postSuccess: false })}
			/>
				<div className="col-md-12 col-sm-12 col-xs-12 mobile-no-padding no-padding-sides">
					<div className="col-md-6 col-sm-6 col-xs-12 resp_Secb">
						<div className="resp_Seca">
							<div className="col-md-12 col-sm-12 col-xs-12">
								<span className="resp_tutorialrsp">Enter your comments</span>
								<textarea rows="4" cols="50" placeholder="Leave your comments" className="text_respart" id="message"></textarea>
							</div>
							<div className="col-md-12 col-sm-12 col-xs-12">
								<div className="btn_puba"></div>

								  <div className="btn_pubb">
									<span className="ret_check">

									<select id = "anon">
            							<option value="anonymous">post anonymously</option>
            							<option value="real">Use my real name</option>            
          							</select>

									</span>

									<span className="ret_pub" onClick={() => this.handleClick()}>Post Comment</span>
								  </div>
							</div>
						</div>
					</div>
					<div>
					<div>
						{this.state.list.map((obj,index) => {
							return this.renderResponse(obj,index)
						})}
						
					</div>
				</div>
				</div> 
				<div className="col-sm-12 col-md-12 col-xs-12 button_position">
					{this.buttonDisplay()}
				</div>
			</div>
		)
	}
}

export default TutorialComments;

