import React from 'react';
import Header from './header';
import Footer from './footer';
import '../styles/knowledgeblock.css';
import '../styles/userDelation.css';

class UserDataDelation extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div className="outer_div">
                <div className="top_div">
                <div className="main_div">
                    <Header />
                </div>
                <div className="container">
                    <h3>
                        We are pleased to honor Facebook's data deletion policies! Please email <a>team@knowledgelocker.com</a> for deleting your facebook user data from our servers
                    </h3>
                </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default UserDataDelation;
