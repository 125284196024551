import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import axios from 'axios';
import BaseUrl from '../config/properties';

import {PinchView} from 'react-pinch-zoom-pan'

class CareerChart extends React.Component {
	
		constructor() {
			super();
			this.state = { 
			data:[],
			jobDetails:[] };
		}
		componentDidMount() {
		//console.log(this.props.careerpath)
        axios.get(BaseUrl.base_url+"/api/v1/CareerPaths/Name/"+this.props.careerpath+"/GeneralDetails").then((response)=>{
        	console.log(response.data.jobRolesList);
            this.setState({ 
            	data: response.data,
				jobDetails: response.data.jobRolesList
            });
        })
    }
	renderJob(obj,index) {
		var number=index+1;
		return (
			<div className="jobs" key ={index}>
				<div className="col-md-12 col-sm-12 col-xs-12 jobDetails">
					<p className="jobTitle"><b>{obj.jobRole_Name}</b></p>
					<p className="jobDecs">{obj.jobRole_Description}</p>
					<p className="jobTech">{obj.jobRole_Technologies_Associated}</p>
				</div>
			</div>
		
		)
	}
	
	render() {
		return (
			<div className="chartdiv">
				
			
    <PinchView debug backgroundColor='#ddd' maxScale={4} containerRatio={((400 / 600) * 100)}>
        

       <img src ={this.state.data.careerPath_CareerChart_ImageLink} className="careerchart"        
        style={{
          margin: 'auto',
          width: '100%',
          height: 'auto'
        }}
        />

   </PinchView>

				<div className="jobRoles"><p>Job Roles :</p></div>
				{this.state.jobDetails.map((obj,index) => {
					return this.renderJob(obj,index);
				})}
			</div>
					
		
		)
	}
}

export default CareerChart;
