import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import axios from 'axios';
import BaseUrl from '../config/properties';
import SweetAlert from 'sweetalert-react';
require('../styles/knowledgeblocklisting.css'); 
require('../styles/sweetalert.css');
const rss = 'https://s3.amazonaws.com/klockerimg/rss.png';
const followed = "https://s3.amazonaws.com/klockerimg/follow-blue.png";
const videosImage = "https://s3.amazonaws.com/klockerimg/play-button-1-copy-2.png";
const articlesImage ="https://s3.amazonaws.com/klockerimg/article.png";

function FollowedImage(props) {
		return(
			<div>
				<img src ={ followed } className="following_image cursor" onClick={props.followingIt} />
				<span className="block_activity_unfollow cursor" onClick={props.followingIt}>&nbsp;Unfollow</span>
			</div>
		)
	}
	
function FollowImage(props) {
		return(
			<div>
				<img src ={ rss } className="cursor" onClick={props.followIt} />
				<span className="block_activity cursor" onClick={props.followIt}>&nbsp;Follow</span>
			</div>
		)
	}

class CareerPathListing extends React.Component {

	constructor(props) {
	    super(props); 
	    this.state = { data: [],
	    			   authToken :'',
	    			   followItSuccess:false,
	    			   followItWait:false,
					   unfollowSuccess:false
	    			};
	  }
	
	componentDidMount() { 
		let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
		if (isLoggedIn == true)  {
			let authToken = localStorage.getItem('authToken');
			this.setState({authToken : authToken},() =>{
				this.getAllData();
			});
	   } else {
		   this.getAllData();
	   }
	}
	   getAllData () {
		   if(this.state.authToken) {
			   axios({
				  method: 'get',
				  url: BaseUrl.base_url+"/api/v1/CareerPaths/getAllCareerPathsForUser",
				  headers: {
					'Authorization':this.state.authToken
				  },
				}).then(response => {
					console.log(response.data.careerPaths);
					console.log('aaa');
				
					let newDAta=[];
					this.setState({ 
						data: response.data.careerPaths,
						resultsCount:response.data.resultsCount
					});
					console.log(this.state.dataKB)
				})
				.catch(error => {
				  throw(error);
				});
		   }
		   else {
				axios.get(BaseUrl.base_url+"/api/v1/CareerPaths/getAllCareerPaths").then((response)=>{
				console.log(response.data.careerPaths);
				let newDAta=[];
				this.setState({ 
            	data: response.data.careerPaths,
				resultsCount:response.data.resultsCount
            });
        })
		   }
	   }
        

   
	/* followIt(careerPathId){
		if (this.state.authToken) {
			axios.post(BaseUrl.base_url+"/api/v1/CareerPath/FollowCareerPath?UserToken="+this.state.authToken+"&CareerPathId="+careerPathId).then((response)=>{
			      this.setState({ followItSuccess: true });
			});
		}else{
		      this.setState({ followItWait: true });
		}
	} */
	
		followIt(careerPathId,value){
		var param = {
				"CareerPathId":careerPathId,
				"OptionValue":value
			}
		if (this.state.authToken) {
			axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/CareerPath/FollowUnFollowCareerPath",
			  headers: {
				'Content-Type': 'application/json',
				'Authorization':this.state.authToken
			  },
				data: param
				}).then(response => {
					console.log(response)
					if(response.data.success == "Following Career Path"){
						this.setState({ followItSuccess: true });
						this.getAllData();
					}
					else if(response.data.success == "UnFollowed Career Path") {
						this.setState({ unfollowSuccess: true });
						this.getAllData();
					}
				})
				.catch(error => {
				  throw(error);
				});
			}else{
				this.setState({ followItWait: true });
			}
			
			/* axios.post(BaseUrl.base_url+"/api/v1/KnowledgeBlock/FollowKnowledgeBlock?UserToken="+this.state.authToken+"&KnowledgeBlockId="+knowledgeBlockId).then((response)=>{
				this.setState({ followItSuccess: true });
			});
		}else{
		      this.setState({ followItWait: true });
		} */
	}
		
		
	searchFun(){
		var searchKey = document.getElementById('searchKey').value;
		axios.get(BaseUrl.base_url+"/api/v1/CareerPaths/SearchByKeywords?query="+searchKey).then((response)=>{
        	console.log(response.data.careerPaths);
            this.setState({ 
            	data: response.data.careerPaths,
            	resultsCount:response.data.resultsCount
            });
        });
	}
    renderList(obj) {
    	var background = {
			backgroundImage : "url(" + obj.careerPathImageLink + ")",
			backgroundRepeat  : 'no-repeat',
			backgroundSize : '100% 100%',
			width:"100%",
			height:"254px"
		};
		return (
			<div key={obj.careerPathId}>
				<div className="col-md-4 col-sm-6 col-xs-12 extra-padding right-padding-zero">
	            <div className="block">
	            	<div className=" col-md-12 col-sm-12 col-xs-12 block_image">
	            		<Link to={"/careerdetails/"+obj.careerPathName_UrlEncoded+"/career_chart_and_job_roles"}><div style= { background } ></div></Link>
	            	</div>
	            	<div className ="block_content">
	            		<div className="sub_content">
		            		<Link to={"/careerdetails/"+obj.careerPathName_UrlEncoded+"/career_chart_and_job_roles"}><p className="block_title">{obj.careerPathName}</p></Link>
		            		<Link to={"/careerdetails/"+obj.careerPathName_UrlEncoded+"/career_chart_and_job_roles"}><p className="block_description">{obj.careerPathDescription}</p></Link>
	            		</div>
	            		<div>
	            			<div className="block_notifications follow_width">
								{/* <img src = {rss} /><span className="block_activity">&nbsp;Follow</span> */}
								{obj.isCurrentUserFollowing?<FollowedImage
															 followingIt ={() => this.followIt(obj.careerPathId,0)}
															 />:
															 <FollowImage
															 followIt = {() => this.followIt(obj.careerPathId,1)}
															 />
								}
	            			</div>
	            			<div className="block_notifications resource_width">
	            				<img src = {videosImage} /><span className="block_activity">&nbsp;{obj.video_Article_ResourceCount} Resources</span>
	            			</div>
	            			<div className="block_notifications knowledge_width">
	            				<img src = {articlesImage} /><span className="block_activity">&nbsp;{obj.knowledgeBlockCount}  Blocks</span>
	            			</div>
	            		</div>
	            	</div>	        	
	            </div>
	        </div>
			</div>
			
		)
	}
	renderThis(){
		if (this.state.data) {
        	return(
        		this.state.data.map((obj) => {
        			return this.renderList(obj)
        		})
        		)
        }else{
        	return(<p>Not found</p>);
        }
	}
	render() {
        return (
        	<div>
        		<SweetAlert
				show={this.state.followItWait}
				title="Wait"
				type="warning"
				text="You need to login in order to follow"
				onConfirm={() => this.setState({ followItWait: false })}
				/>
				<SweetAlert
				show={this.state.followItSuccess}
				title="Success"
				type="success"
				text="You are following this career path"
				onConfirm={() => this.setState({ followItSuccess: false })}
				/>
				<SweetAlert
				show={this.state.unfollowSuccess}
				title="Success"
				type="success"
				text="You have unfollowed this career path successfully"
				onConfirm={() => this.setState({ unfollowSuccess: false })}
				/>
        		<div className="col-md-12 kb_title">
						<p  className="kb_title_p">CareerPaths</p>
						<div className="search-container">
							<form action='javascript:void(0)' onSubmit={()=>this.searchFun()}><input className='search-box' id="searchKey" type='text' placeholder= 'Search for a Careerpath by name' /><button type='submit' className="search-button"><i className="fa fa-search" aria-hidden="true"></i></button></form>
						</div>
						<p className="kb_info">Showing {this.state.resultsCount} CareerPaths</p>
				</div>
        		{this.renderThis()}
        		
        	</div>
        )	
    }
}

export default CareerPathListing;