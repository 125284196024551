import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Select } from 'antd';
import axios from 'axios';
import BaseUrl from '../config/properties';
import ReactPlayer from 'react-player';
import SweetAlert from 'sweetalert-react';
import Article from './article';
const Option = Select.Option;
var Filter = require('../helper/filter');

function Player(props) {
	return(
		<ReactPlayer
		  url={props.video}
		  width = '330'
		  height = '201.8'
		  youtubeConfig={{ playerVars: { showinfo: 1 } }}
		/>
		);
}

class VideosAndBlogs extends React.Component {
	
	constructor(props) {
	    super(props);
		this.state = { 
				  mainDataVideo:[],
				  mainDataArticle:[],
				  dataVideo:[],
				  dataArticle:[],
				  authToken:'',
				  rating:{},
				  votedLoggedIn:false,
				  votedAnonymous:false,
				  notVoted:false
				};
	}
	
	
	
	videosArticle(obj,index){
		var title = null;
		var level = null;
		var key = null;
		var skillImage = null;
		var ratingpositive=null;
		var ratingneutral=null;
		var ratingnegative=null;
		var articleImage = null;
		if(obj.public_WebArticle_Image_URL) {
			articleImage = {
				backgroundImage : "url("+obj.public_WebArticle_Image_URL+")",
				backgroundRepeat  : 'no-repeat',
				backgroundSize: "100% 100%"
			};
		}else {
			articleImage = {
				backgroundImage : "url(https://s3.amazonaws.com/klockerimg/article_default.jpg)",
				backgroundRepeat  : 'no-repeat',
				backgroundSize: "100% 100%"
			};
		} 
		
		if(obj.resource_Type=="video"){
			title=obj.public_WebVideo_Resource_Title;
			level=obj.public_WebVideo_Resource_Level;
			key=obj.public_WebVideo_Resource_Id;
			//preview=obj.public_WebVideo_Resource_URL;
			ratingpositive=obj.videoRatingsDistribution.positiveRatingsCount;
			ratingnegative=obj.videoRatingsDistribution.negativeRatingsCount;
			ratingneutral=obj.videoRatingsDistribution.neutralRatingsCount;
			
		}else{
			title=obj.public_WebArticle_Resource_Title;
			level=obj.public_WebArticle_Resource_Level;
			key=obj.public_WebArticle_Resource_Id;
			//preview="";
			ratingpositive=obj.articleRatingsDistribution.positiveRatingsCount;
			ratingnegative=obj.articleRatingsDistribution.negativeRatingsCount;
			ratingneutral=obj.articleRatingsDistribution.neutralRatingsCount;
		}
		if((obj.public_WebVideo_Resource_Level || obj.public_WebArticle_Resource_Level) == 'Beginner') {
			skillImage ="https://s3.amazonaws.com/klockerimg/speed-meter.png";
		}
		else if((obj.public_WebVideo_Resource_Level || obj.public_WebArticle_Resource_Level) == 'Intermediate') {
			skillImage ="https://s3.amazonaws.com/klockerimg/intermediate.png";
		}
		else if((obj.public_WebVideo_Resource_Level || obj.public_WebArticle_Resource_Level) == 'Advanced') {
			skillImage ="https://s3.amazonaws.com/klockerimg/advanced.png";
		}
		return(
			<div className="col-md-4 col-sm-6 col-xs-12" key= {index}>
				<div className="Rectangle-29">
					{ obj.resource_Type=='video' ? <Player video={obj.public_WebVideo_Resource_URL} /> : <div className='Rectangle-3' style= {articleImage}><p className='tag-btn'>{obj.resource_Type}</p></div>}
					<div className="col-md-12 col-sm-12 col-xs-12 custom-margin">
						<div className="col-md-8 col-sm-8 col-xs-9 no-padding">
							<div className="custom-width title-name">

							{obj.resource_Type=='video' ? <a href={obj.public_WebVideo_Resource_URL} target="_blank">{title}</a> : ''} 

							{obj.resource_Type=='article' && obj.public_WebArticle_Resource_Is_Internal_Article == true ? <Link to={"/article/"+obj.public_WebArticle_Resource_Article_Name_In_Internal_URL} >{title}</Link> : '' } 

							{obj.resource_Type=='article' && obj.public_WebArticle_Resource_Is_Internal_Article == false ?  <a href={obj.public_WebArticle_Resource_URL} target="_blank">{title}</a> :  '' } 


							</div>
							<div className="custom-width margin-top-4">
								<div className="col-md-3 col-sm-3 col-xs-3 no-padding-left">
									<p className="rate">Rate</p>
								</div>
								<div className="col-md-3 col-sm-3 col-xs-3">
									<img src="https://s3.amazonaws.com/klockerimg/group-21-copy-2.png" className="Group-21-Copy-2" onClick={() => this.reactToThis(key,obj.resource_Type,1) }/>
									<p className="number-style">{ratingpositive}</p>
								</div>
								<div className="col-md-3 col-sm-3 col-xs-3">
									<img src="https://s3.amazonaws.com/klockerimg/group-33-copy-2.png" className="Group-33-Copy-2" onClick={() => this.reactToThis(key,obj.resource_Type,0)}/>
									<p className="number-style">{ratingneutral}</p>
								</div>
								<div className="col-md-3 col-sm-3 col-xs-3">
									<img src="https://s3.amazonaws.com/klockerimg/sad-copy-2.png" className="sad-copy-2" onClick={() => this.reactToThis(key,obj.resource_Type,-1)}/>
									<p className="number-style">{ratingnegative}</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-sm-4 col-xs-3 no-padding center">
							<img src={skillImage} className="speed-meter"/>
							<p className="skill-level">Skill Level</p>
							<p className="beginner">{level}</p>
						</div>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
		let authToken=null;
		console.log(this.props.knowledgeBlockName)
		if (isLoggedIn == true)  {
			authToken = localStorage.getItem('authToken');
			this.setState({authToken : authToken});
			console.log(authToken)
		}
		this.getAlldata();
	}
		getAlldata() {
		 axios.get(BaseUrl.base_url+"/api/v1/KnowledgeBlocks/Name/"+this.props.knowledgeBlockName+"/Videos_Articles").then((response)=>{
            if (response.data.length) {
            	if (response.data[0].length) {
            		this.setState({ 
            			mainDataVideo: response.data[0],
		            	dataVideo: response.data[0]
		            });
            	}
            	if (response.data[1].length) {
            		this.setState({ 
            			mainDataArticle: response.data[1],
		            	dataArticle: response.data[1]
		            });
            	}
            }
        });
		}
	
		reactToThis(resourceId,resource_Type,opinion) {
		console.log('aaa')
		if(resource_Type=="video") {
			var param = {
				"videoId":resourceId,
				"opinion":opinion
			}
			if (this.state.authToken) {
			console.log('video')
			axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/AddPublicVideoOpinion",
			  headers: {
				'Content-Type': 'application/json',
				'Authorization':this.state.authToken
			  },
				data: param
				}).then(response => {
				 this.setState({ votedLoggedIn: true });
				 this.getAlldata();
				})
				.catch(error => {
				  throw(error);
				});
			}else{
				console.log('video without auth')
				axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/AddPublicVideoOpinion",
			  headers: {
				'Content-Type': 'application/json',
			  },
				data: param
				}).then(response => {
				  this.setState({ votedAnonymous: true });
				  this.getAlldata();
				})
				.catch(error => {
				  throw(error);
				});
			}
		}else {
			console.log('bbb')
			var param = {
				"articleId":resourceId,
				"opinion":opinion
			}
			if (this.state.authToken) {
			console.log('article')
			axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/AddPublicArticleOpinion",
			  headers: {
				'Content-Type': 'application/json',
				'Authorization':this.state.authToken
			  },
				data: param
				}).then(response => {
				  this.setState({ votedLoggedIn: true });
				  this.getAlldata();
				})
				.catch(error => {
				  throw(error);
				});
			}else{
				console.log('article without auth')
				axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/AddPublicArticleOpinion",
			  headers: {
				'Content-Type': 'application/json',
			  },
				data: param
				}).then(response => {
				  this.setState({ votedAnonymous: true });
				  this.getAlldata();
				})
				.catch(error => {
				  throw(error);
				});
			}
			
		}
	}

	 handleChange(value) {
		console.log(value);
		var filterValue = value;
		
		if(filterValue == 'All') {
			console.log('all in')
			this.getAlldata();	
		}else {
			var arrOfObj = {video: this.state.mainDataVideo,
                        article: this.state.mainDataArticle};
			this.filter(filterValue, arrOfObj, this);
		}
	}

	filter(filterValue, arrOfObj, props){
		Filter.filterObject(filterValue, arrOfObj, function(err,result){
            console.log({after:result,isTrue:true});
            props.setState({ 
                dataVideo: result.video,
                dataArticle: result.article,
            });
        });
	}
	callback(key) {
		console.log(key);
	}
		
	
		 

	render() {
		
		 return (
			<div>
				<SweetAlert
					show={this.state.votedLoggedIn}
					title="Success"
					type="success"
					text="Voted this item successfully"
					onConfirm={() => this.setState({ votedLoggedIn: false })}
				/>
				<SweetAlert
					show={this.state.votedAnonymous}
					title="Success"
					type="success"
					text="Voted this item successfully anonymously"
					onConfirm={() => this.setState({ votedAnonymous: false })}
				/>
				<div className="col-md-12 extra-padding margin-top-10">
					<label>Filter:&nbsp;&nbsp;&nbsp;</label>
					<Select
						showSearch
						style={{ width: 200 }}
						placeholder="Select preferred skill level"
						optionFilterProp="children"
						onChange={this.handleChange.bind(this)}
						filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					>
					<Option value="All">All</Option>
					<Option value="Beginner">Beginner</Option>
					<Option value="Intermediate">Intermediate</Option>
					<Option value="Advanced">Advanced</Option>
				  </Select>
				</div>
				<div className="row">
					{this.state.dataVideo.map((obj,index) => {
						return this.videosArticle(obj,index);
					})}
					{this.state.dataArticle.map((obj,index) => {
						return this.videosArticle(obj,index);
					})}
				</div>
			</div>
		)
	}
}

export default VideosAndBlogs;
