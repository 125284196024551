import React from 'react'
import PropTypes from "prop-types"
import axios from "axios";
import BaseUrl from "../config/properties";

class PayPal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editModelShow: false,
        };
    }
    componentWillMount() {
        const that = this
        if(!this.state.editModelShow) {
            this.setState({
                editModelShow: true,
            },() => {
                // paypal.Button.render({
                //
                //     env: 'production', // sandbox | production
                //     client: {
                //         sandbox:    'AYtnAl7gkUzvrKgM_D2X2adNRS_WuhOTC7XkkcWILEHP_ajb4QAj3zuDB2DsvOeuvgXR7JOZ9fp0fq2I',
                //         production: 'AXsGhTo5jCqobjwsA_niVqqiXtOuuImsjuj9vpfVZIf6unl75F1ZuQTddF4TRKkXsQEbOXN03xp0Dr1C'
                //     },
                //     commit: true,
                //
                //     payment: function(data, actions) {
                //
                //         // Make a call to the REST api to create the payment
                //         return actions.payment.create({
                //             payment: {
                //                 transactions: [
                //                     {
                //                         amount: { total: parseInt(that.props.total || 0,10), currency: that.props.currencyCode }
                //                     }
                //                 ]
                //             }
                //         });
                //     },
                //
                //     onAuthorize: function(data, actions) {
                //
                //         // Make a call to the REST api to execute the payment
                //         return actions.payment.execute().then(function() {
                //             console.log("================>", data)
                //             that.onSuccess(data)
                //         });
                //     },
                //
                //     onCancel: (data) => {
                //         console.log('The payment was cancelled!', data);
                //     },
                //     onError: (err) => {
                //         console.log('Error loading Paypal script!', err);
                //     },
                // }, `#${this.props.paypalId}`);
            })
        }

    }

    onSuccess (payment){
        const {courseName, total} = this.props
        let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        let authToken = null;
        let userData = [];
        const payload = {
            "PaymentGateway":"paypal",
            "PurchasePrice": total,
            "PaymentToken": payment && payment.paymentToken,
            "PaymentID": payment && payment.paymentID,
            "PayerID": payment && payment.payerID
        }
        if (isLoggedIn) {
            authToken = localStorage.getItem('authToken');
            userData = JSON.parse(localStorage.getItem('userData'));
            console.log(userData);
            this.setState({
                authToken:authToken,
                userData:userData,
                url:userData.picture.data.url
            });
        }
        if (authToken) {
            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('authToken')
                }
            }
            axios.post(BaseUrl.base_url + `/api/v1/Courses/Name/${courseName}/Purchase`,payload, config).then((response) => {
                if (response.data) {
                    this.setState({
                        coursePay: response.data.status.toLowerCase(),
                    }, () => window.location.reload())
                }
            }).catch((error) => {
                this.setState({error: error.message})
            });
        }
    }

    render() {
        return(
            <div id={this.props.paypalId}/>
        )
    }
}

PayPal.propTypes = {
    paypalId: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    courseName: PropTypes.string.isRequired
};

export default PayPal;