import React from 'react';
import Header from './header';
import Footer from './footer';
// import {Row, Col, Icon, Modal, Button, Menu, Checkbox } from ' @ant-design/icons'
import {Row, Col, Modal, Button, Menu, Checkbox } from 'antd'
import axios from 'axios';
import BaseUrl from '../config/properties';
import '../styles/TestDetails.css'
require('../styles/courses.css');


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class courseHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editModelShow: false,
        key: "Intro",
        userHasCompletedLesson: true,
        microLesson_Id: ''
    };
  }

   componentWillMount() {
      const path = window.location.pathname.slice(8);
      const courseName = path.substr(0, path.indexOf('/'));
      let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
      let authToken = null;
      let userData = [];
      if (isLoggedIn === true)  {
          authToken = localStorage.getItem('authToken');
          userData = JSON.parse(localStorage.getItem('userData'));
          console.log(userData);
          this.setState({
              authToken:authToken,
              userData:userData,
              url:userData.picture.data.url,
              courseName: courseName
          });
      }
      if (authToken) {
          const config = {
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: localStorage.getItem('authToken')
              }
          }
          axios.get(BaseUrl.base_url + `/api/v1/Courses/Name/${courseName}/DetailsForPurchasedUser`, config).then((response) => {
              if (response.data) {
                  this.setState({
                      courseDescription: response.data,
                  })
              }
          }).catch((error) => {
              this.setState({error: error.message})
          });
          axios.get(BaseUrl.base_url + `/api/v1/Courses/Name/${courseName}/hasUserCompleted`, config).then((response) => {
              if (response.data) {
                  this.setState({
                      courseComplete: response.data.status.toLowerCase(),
                  })
              }
          }).catch((error) => {
              this.setState({error: error.message})
          });
      }
  }

  handleClick(e) {
        console.log('click ', e);
  }

  viewLessons (item, key) {
      const config = {
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: localStorage.getItem('authToken')
          }
      }
      axios.get(BaseUrl.base_url + `/api/v1/Lesson/${item.microLesson_Id}/Contents`, config).then((response) => {
          if (response.data) {
              this.setState({
                  lessonContent: response.data,
                  userHasCompletedLesson: item.userHasCompletedLesson,
                  microLesson_Id: item.microLesson_Id
              }, () => this.runCodePrettify())
          }
      }).catch((error) => {
          this.setState({error: error.message})
      });
    this.setState({
        lesson: item,
        key: key
    })
  }

  course() {
      const {courseName} = this.state
      const config = {
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: localStorage.getItem('authToken')
          }
      }
      axios.post(BaseUrl.base_url + `/api/v1/Courses/Name/${courseName}/completeCourse`,'', config).then((response) => {
          if (response.data) {
              this.setState({
                  courseComplete: response.data.status.toLowerCase() === 'success' ? 'true' : 'false',
              })
          }
      }).catch((error) => {
          this.setState({error: error.message})
      });
  }

  completeLesson () {
      const { microLesson_Id, courseDescription }  = this.state
        const config = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('authToken')
            }
        }
        axios.post(BaseUrl.base_url + `/api/v1/Lesson/${microLesson_Id}/completeLesson`,'', config).then((response) => {
            if (response.data && response.data.status === 'success') {
                const lesson = (courseDescription.microLessonsList || []).find(x=>x.microLesson_Id === microLesson_Id);
                if (lesson) {
                    lesson.userHasCompletedLesson = true;
                }
                this.setState({
                    courseDescription
                })
            }
        }).catch((error) => {
            this.setState({error: error.message})
        });
  }

  showMenu (item, key) {
      const {userHasCompletedLesson} = this.state
      return (
          <div>
              <Col span={18}>
                  <div className="form-row col-md-12">
                      <Button type="primary" onClick={() => this.props.history.push('/courses')}>
                          {/*<Icon type="left"/>*/}
                          Back to Courses
                      </Button>
                      {
                          userHasCompletedLesson === true ?
                              <p className="course-complete" style={{textAlign: "right"}}>You have already marked this lesson as completed.</p>
                              : <Button type="primary" onClick={() => this.completeLesson()} style={{textAlign: "right", float: "right"}}>Mark This Lesson As Completed</Button>
                      }
                  </div>
                  <div className="form-row">
                      <div className="form-group col-md-12">
                          <p className="menu-name" >Lesson Name:</p>
                          <p>{item && item.microLesson_Name}</p>
                      </div>
                  </div>
                  <div className="form-row">
                      <div className="form-group col-md-12">
                          <div dangerouslySetInnerHTML={{ __html: this.state.lessonContent }} />
                      </div>
                  </div>
              </Col>
          </div>
      )
  }

  runCodePrettify() {
      console.log({isCalled:'yes'})
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js';
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
  }

  render() {
    const { courseComplete, courseDescription, lesson, key } = this.state
    return (
        <div>
            <div className="main_div">
                <Header/>
            </div>
            <div className="container">
                <div className="col-md-12 col-sm-12 col-xs-12 article_content">
                    <div>
                        <div className={'col-md-12 col-sm-12'}>
                            <div className="block_locker course-body">
                                <Row gutter={8}>
                                    <Col span={6} className="text-center">
                                        <Menu
                                            onClick={() =>this.handleClick}
                                            style={{ width: 256, textAlign: 'left'}}
                                            defaultSelectedKeys={['sub1']}
                                            defaultOpenKeys={['sub2']}
                                            mode="inline"
                                        >
                                            <Menu.Item key="sub1">
                                                <p onClick={() => this.viewLessons(courseDescription && courseDescription.microCourse_Name, "Intro")}>Introduction</p>
                                            </Menu.Item>
                                            <SubMenu key="sub2" title={<span>
                                                {/*<Icon type="appstore" />*/}
                                                <span>Lessons</span></span>
                                            }>
                                                {
                                                    courseDescription && (courseDescription.microLessonsList || []).map((item,i) => (
                                                        <Menu.Item key={i} title={item.microLesson_Name}>
                                                            <div className={item.userHasCompletedLesson ? 'green' : ''}>
                                                                <Checkbox checked= {item.userHasCompletedLesson ? true : false} />
                                                                <span  onClick={() => this.viewLessons(item, "Lesson")}> {item.microLesson_Name}</span>
                                                            </div>
                                                        </Menu.Item>
                                                        )
                                                    )
                                                }
                                            </SubMenu>
                                        </Menu>
                                    </Col>
                                    {
                                        key === "Intro" ?
                                            <div>
                                                <Col span={18}>
                                                    <div className="form-row col-md-12">
                                                        <Button type="primary" onClick={() => this.props.history.push('/courses')}>
                                                            {/*<Icon type="left" />Back to Courses*/}
                                                        </Button>
                                                        {
                                                            courseComplete === 'true' ?
                                                                <p className="course-complete" style={{textAlign: "right"}}>Course is marked completed.User can still view the contents just like before.</p>
                                                                : <Button type="primary" onClick={() => this.course()} style={{textAlign: "right", float: "right"}}>Mark course as completed</Button>
                                                        }
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <p className="menu-name">Course Name:</p>
                                                            <p>{courseDescription && courseDescription.microCourse_Name}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <p>Please Select a Lessons from the List</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div> :
                                        this.showMenu(lesson, key)
                                    }
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
  }
}

export default courseHome;
