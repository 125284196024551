import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SocialLogin from '../facebook/index'
import '../styles/fb.css'
class Button extends Component {
    static propTypes = {
        triggerLogin: PropTypes.func.isRequired,
        triggerLogout: PropTypes.func.isRequired
    }

    render () {

        return (
            <>
                <span onClick={this.props.triggerLogin} className="header-nav-item" id="facebook_login">
                    <i className="fa fa-facebook" aria-hidden="true" />
                         &nbsp;Login with Facebook
                </span>

            </>
        )
    }
}

export default SocialLogin(Button)
