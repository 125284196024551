import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Header from './header';
import AdvertisementVertical from './advertical';
import TutorialComments from './tutorialcomments';
import BaseUrl from '../config/properties';
import Footer from './footer';
import axios from 'axios';

require('../styles/article.css');
require('../styles/style.css');

class Tutorial extends React.Component {
	constructor(props) {
	    super(props);
	    this.state={
	    	tutorialContent:[]
	    }
	  } 
  
	componentDidMount(){
		ReactDOM.findDOMNode(this).scrollIntoView();
		let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
		let authToken = null;
		if (isLoggedIn == true)  {
			 authToken= localStorage.getItem('authToken');
	   }
	   console.log({authToken});
	   	axios.get(BaseUrl.base_url+"/api/v1/Tutorial/Name/"+this.props.match.params.tutorialName).then((response)=>{
				console.log(response.data);
					this.setState({
						tutorialContent:response.data
					});
			});
	}

	runCodePrettify() {
      console.log({isCalled:'yes'})
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js';
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    }

	render() {
		this.runCodePrettify();

        return (
        	<div>
	            <div className="main_div">
	            	<Header />

				</div>
	            	<div className="container">
						<div className="col-md-12 col-sm-12 col-xs-12 tutorial_content">
							<h3 className="article_innercont">{this.state.tutorialContent.tutorial_Title}</h3>
							<p className="article_innercontwrap"><i>{this.state.tutorialContent.tutorialAuthor_UserName}</i></p>

							<p className="article_innercontwrap"><i className="fa fa-circle dotart" aria-hidden="true"></i>
							<i>{this.state.tutorialContent.tutorialPublishedDate}</i></p>
						</div>
						
						<div className="col-md-8 col-sm-8 col-xs-8">
							<div className="artc_img"><img src={this.state.tutorialContent.tutorial_Image_URL}className="articleImage" /></div>
						</div> 
						
						<div className="col-md-12 col-sm-12 col-xs-12 sec_main">
						    

							<div className="col-md-9 col-sm-9 col-xs-12 txt_art" dangerouslySetInnerHTML={{__html: this.state.tutorialContent.tutorialContentText}}>
							</div>
							<div className="col-md-3 col-sm-3 col-xs-12 side_sec">
							   <p className="art_spon">Sponsored Links</p>
							     <AdvertisementVertical />
							</div>
						</div>
						
						<div className="col-md-12 col-xs-12 col-sm-12">
							<p className="art_joy">Enjoy this tutorial? Don't forget to share.</p>
						</div>
						
						<TutorialComments tutorialUrl={this.props.match.params.tutorialName}/>
						
						<div className="col-md-12 col-sm-12 col-xs-12 resp_tutorialrel">
							<div className="col-md-12 col-sm-12 col-xs-12">
							</div>

								{/*
								
						
						<div>
							<div className="col-md-4 col-sm-6 col-xs-12 extra-padding">
							<div className="block">
							  
							  <div className="col-md-12 col-sm-12 col-xs-12  block_image">
							    <div className="image_article"></div>
							   </div>
							  
							     <div className="block_content">
							        <div className="sub_content">
								 	</div>
								   <div>
								   <div className="block_notifications"></div>
									<div className="block_notifications"></div>
									<div className="block_notifications">
								    </div>
                     				</div>
								 </div>



								</div>
								</div>
								
						</div>

							*/}



						</div>
						
						
		             </div>
		             

					 <Footer />
				</div>
	            
				
        )
    }
}

export default Tutorial;