import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import axios from 'axios';
import SweetAlert from 'sweetalert-react';
import BaseUrl from '../config/properties';

import '../styles/knowledgeblocklisting.css';
import '../styles/sweetalert.css';
//const rss = require('../images/rss.png');
const rss = 'https://s3.amazonaws.com/klockerimg/rss.png';
const followed = "https://s3.amazonaws.com/klockerimg/follow-blue.png";
const videosImage = "https://s3.amazonaws.com/klockerimg/play-button-1-copy-2.png";
const articlesImage ="https://s3.amazonaws.com/klockerimg/article.png";
const tick ="https://s3.amazonaws.com/klockerimg/tick.png";


function FollowedImage(props) {
		return(
			<div>
				<img src ={ followed } className="following_image cursor" onClick={props.followingIt} />
				<span className="block_activity_unfollow cursor" onClick={props.followingIt}>&nbsp;Unfollow</span>
			</div>
		)
	}
	
function FollowImage(props) {
		return(
			<div>
				<img src ={ rss } className="cursor" onClick={props.followIt} />
				<span className="block_activity cursor" onClick={props.followIt}>&nbsp;Follow</span>
			</div>
		)
	}

class CareerKnowledgeBlock extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = { data: [],
	    			   authToken :'',
	    			   followItSuccess:false,
	    			   followItWait:false,
					   unfollowSuccess:false
	    			};
	  }
	
	componentDidMount() {
		let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
		if (isLoggedIn == true)  {
			let authToken = localStorage.getItem('authToken');
			this.setState({authToken : authToken}, () => {
				this.getAllData();
			});
	   } else {
		   this.getAllData();
	   }
	}
	   getAllData() {
		   if(this.state.authToken) {
			   axios({
				  method: 'get',
				  url: BaseUrl.base_url+"/api/v1/KnowledgeBlocks/getKnowledgeBlocksForCareerPath/"+this.props.careerpath,
				  headers: {
					'Authorization':this.state.authToken
				  },
				}).then(response => {
					console.log(response.data);
					console.log('aaa');
				
					let newDAta=[];
					if (response.data.resultsCount>0) {
						this.setState({ 
							data: response.data.knowledgeBlocks
						});
					}
				})
				.catch(error => {
				  throw(error);
				});
		   }
		   else {
			axios.get(BaseUrl.base_url+"/api/v1/KnowledgeBlocks/getKnowledgeBlocksForCareerPath/"+this.props.careerpath).then((response)=>{
				console.log(response.data);
				
				let newDAta=[];
				if (response.data.resultsCount>0) {
					this.setState({ 
						data: response.data.knowledgeBlocks
					});
				}
				})
			}
	   }
        

   
	/* followIt(careerPathId){
		if (this.state.authToken) {
			axios.post(BaseUrl.base_url+"/api/v1/CareerPath/FollowCareerPath?UserToken="+this.state.authToken+"&CareerPathId="+careerPathId).then((response)=>{
			      this.setState({ followItSuccess: true });
			});
		}else{
		      this.setState({ followItWait: true });
		}
	} */
	
	followIt(knowledgeBlockId,value){
		var param = {
				"KnowledgeBlockId":knowledgeBlockId,
				"OptionValue":value
			}
		if (this.state.authToken) {
			axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/KnowledgeBlock/FollowUnFollowKnowledgeBlock",
			  headers: {
				'Content-Type': 'application/json',
				'Authorization':this.state.authToken
			  },
				data: param
				}).then(response => {
					console.log(response)
					if(response.data.success == "Following knowledge block"){
						this.setState({ followItSuccess: true });
						this.getAllData();
					}
					else if(response.data.success == "UnFollowed knowledge block") {
						this.setState({ unfollowSuccess: true });
						this.getAllData();
					}
				})
				.catch(error => {
				  throw(error);
				});
			}else{
				this.setState({ followItWait: true });
			}
			
			/* axios.post(BaseUrl.base_url+"/api/v1/KnowledgeBlock/FollowKnowledgeBlock?UserToken="+this.state.authToken+"&KnowledgeBlockId="+knowledgeBlockId).then((response)=>{
				this.setState({ followItSuccess: true });
			});
		}else{
		      this.setState({ followItWait: true });
		} */
	}
		
	
    renderList(obj) {
		var background  = {backgroundImage : "url(" + obj.knowledgeBlock_ImageURL + ")",
					  backgroundRepeat  : 'no-repeat',
					  backgroundSize: '100% 100%',
					  width:"100%",
					  height:"254px"
					};
		if(this.state.data != null) {
		return (
			<div key={obj.knowledgeBlock_Id}>
				<div className="col-md-4 col-sm-6 col-xs-12 extra-padding">
	            <div className="block">
	            	<div className=" col-md-12 col-sm-12 col-xs-12 block_image">
	            		<Link to={"/knowledgeblockdetails/"+obj.knowledgeBlock_Name_UrlEncoded+"/get_started"}>
							<div style= { background } ></div>
						</Link>
	            	</div>
	            	<div className ="block_content">
	            		<div className="sub_content">
		            		<Link to={"/knowledgeblockdetails/"+obj.knowledgeBlock_Name_UrlEncoded+"/get_started"}><p className="block_title">{obj.knowledgeBlock_Name}</p></Link>
		            		<p className="block_description">{obj.knowledgeBlock_Description}</p>
	            		</div>
	            		<div>
	            			<div className="block_notifications follow_block">
	            				{obj.isCurrentUserFollowing?<FollowedImage
															 followingIt ={() => this.followIt(obj.knowledgeBlock_Id,0)}
															 />:
															 <FollowImage
															 followIt = {() => this.followIt(obj.knowledgeBlock_Id,1)}
															 />
								}
	            			</div>
	            			<div className="block_notifications videos_block">
	            				<img src = {videosImage} /><span className="block_activity ">&nbsp;{obj.knowledgeBlock_ResourceCount} Videos</span>
	            			</div>
	            			<div className="block_notifications tick_block">
	            				<img src = {tick} className="tick" /><span className="block_activity">&nbsp; InterviewPreps</span>
	            			</div>
	            		</div>
	            	</div>	        	
	            </div>
	        </div>
			</div>
			
		)
	}else {
		return (
			<p className="text_center">No KnowledgeBlocks yet for this careerpath. Watch out this space!</p>
		)
	}
	}

	render() {
        return (
        	<div className ="career_kb">
        		<SweetAlert
				show={this.state.followItWait}
				title="Wait"
				type="warning"
				text="You need to login in order to follow"
				onConfirm={() => this.setState({ followItWait: false })}
				/>
				<SweetAlert
				show={this.state.followItSuccess}
				title="Success"
				type="success"
				text="You are following this knowledge block"
				onConfirm={() => this.setState({ followItSuccess: false })}
				/>
				<SweetAlert
				show={this.state.unfollowSuccess}
				title="Success"
				type="success"
				text="You have unfollowed this knowledge block successfully"
				onConfirm={() => this.setState({ unfollowSuccess: false })}
				/>
        		{this.state.data.map((obj) => {
        			return this.renderList(obj)
        		})}
        		
        	</div>
        )	
    }
}

export default CareerKnowledgeBlock;