import React from 'react';
// import { Icon } from '@ant-design/icons'
import {Row, Col, Modal} from 'antd'

class PurchasedCourses extends React.Component {

    render() {
        const PurchaseCourses = this.props.PurchaseCourses || []
        return (
            <div className="block_locker">
                <div className="col-md-12 col-sm-12 col-xs-12 block_image account-pg">
                    <Row gutter={8}>
                        <Col span={21}>
                            <table className="table table-bordered table-hover table-responsive " style={{marginLeft: "40px"}}>
                                <thead>
                                <tr style={{fontSize: "15px", fontWeight: 300, color: "black"}}>
                                    <td>Course Name</td>
                                    <td>Purchase Date</td>
                                    <td>Purchased Price</td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    PurchaseCourses && PurchaseCourses.length ?
                                        PurchaseCourses.map((item, i) => (
                                            <tr key={i}>
                                                <td><a href={`/course/${item.microCourse_Name_UrlEncoded}`}>{item.microCourse_Name}</a></td>
                                                <td>{item.microCourse_PurchaseDate}</td>
                                                <td>{item.microCourse_PurchasedPrice}</td>
                                            </tr>
                                        )) : null
                                }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default PurchasedCourses;
