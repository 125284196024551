import React, { Component } from 'react'

import SocialButton from '../components/socialButton'
import {ProfileTab} from "../components/profileTab";
var isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
export default class Demo extends Component {
    constructor (props) {
        super(props)

        this.state = {
            logged: false,
            user: {},
            currentProvider: '',
            expiresAt:'',

        }
        this.nodes = {}

        this.onLoginSuccess = this.onLoginSuccess.bind(this)
        this.onLoginFailure = this.onLoginFailure.bind(this)
        this.onLogoutSuccess = this.onLogoutSuccess.bind(this)
        this.onLogoutFailure = this.onLogoutFailure.bind(this)
        this.logout = this.logout.bind(this)
    }
    componentDidMount() {
        this.setState({logged : false});
        if (isLoggedIn === true)  {
            let userData = JSON.parse(localStorage.getItem('userData'));
            let provider = localStorage.getItem('provider');
            this.setState({user : userData,logged: true,currentProvider:provider});
        }
    }

    setNodeRef (provider, node) {
        if (node) {
            this.nodes[ provider ] = node
        }
    }

    onLoginSuccess (user) {
        console.log("user---->",user)
        localStorage.setItem("isLoggedIn",true);
        localStorage.setItem("userData",JSON.stringify(user._profile));
        localStorage.setItem("provider",user._provider);
        localStorage.setItem("authToken",user._token.accessToken);
        localStorage.setItem("expiresAt",user._token.expiresAt);
        this.setState({
            logged: true,
            currentProvider: user._provider,
            user:user._profile,
            expiresAt:user._token.expiresAt
        })
        window.location.reload();
    }

    onLoginFailure (err) {
        console.error(err)
        localStorage.removeItem("userData");
        localStorage.removeItem("authToken");
        localStorage.setItem("isLoggedIn",false);
        this.setState({
            logged: false,
            currentProvider: '',
            user: {}
        })
    }

    onLogoutSuccess () {
        localStorage.removeItem("userData");
        localStorage.removeItem("authToken");
        localStorage.setItem("isLoggedIn",false);
        this.setState({
            logged: false,
            currentProvider: '',
            user: {}
        })

    }

    onLogoutFailure (err) {
        console.error(err)
    }

    logout () {
        const { logged, currentProvider } = this.state

        if (logged && currentProvider) {
            this.nodes[currentProvider].props.triggerLogout()
        }
    }

    render () {
        let children

        if (this.state.logged) {
            children =
                <ProfileTab
                    user={this.state.user}
                    getInstance={this.setNodeRef.bind(this, 'facebook')}
                    onLoginSuccess={this.onLoginSuccess}
                    onLoginFailure={this.onLoginFailure}
                    onLogoutSuccess={this.onLogoutSuccess}
                 />
        } else {
            children = [
                <SocialButton
                    provider='facebook'
                    appId='657319568198782'
                    onLoginSuccess={this.onLoginSuccess}
                    onLoginFailure={this.onLoginFailure}
                    onLogoutSuccess={this.onLogoutSuccess}
                    getInstance={this.setNodeRef.bind(this, 'facebook')}
                    key={'facebook'}
                    onInternetFailure = {()=>{return true}}
                >
                </SocialButton>,
            ]
        }
        return children
    }
}
