import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Header from './header';
import Advertisement from './ad';
import KnowledgeBlockListing from './knowledgeblocklisting';
import { Progress } from 'reactstrap';
import VideosAndBlogs from './knowledgeblock_videosAndBlogs';
import KnolwedgeBlock_Interviews_Practice from './knowledgeblock_interviewsAndPractise';
import GetStarted from './knowledgeblock_getStarted';
import TrainingsAndMeetups from './knowledgeblock_trainingsAndMeetups';
import Footer from './footer';
import axios from 'axios';
import BaseUrl from '../config/properties';
import SweetAlert from 'sweetalert-react';
import ReactPlayer from 'react-player';
import { Select } from 'antd';

import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;
require('../styles/antd.css');
require('../styles/knowledgeblockdetial.css');
require('../styles/sweetalert.css');
var star = {
	backgroundImage : "url(https://s3.amazonaws.com/klockerimg/shape.png)",
	backgroundRepeat  : 'no-repeat',
	width:"100%",
	height:"100%"
};
function Player(props) {
	return(
		<div className='wrapper-video'>
			<ReactPlayer
			  url={props.video}
			  width = '100%'
			  height = '100%'
			  youtubeConfig={{ playerVars: { showinfo: 1 } }}
			/>
		</div>
		);
}

const rss = 'https://s3.amazonaws.com/klockerimg/rss_white.png';
const followed = "https://s3.amazonaws.com/klockerimg/unfollow-careerpath.png"


function FollowedImage(props) {
		return(
			<div>
				<button className="cp_following" onClick={props.followingIt} ><img src ={ followed } className="following_image"/>&nbsp;Unfollow</button>
			</div>
		)
	}
	
function FollowImage(props) {
		return(
			<div>
				<button className="cp_follow" onClick={props.followIt} ><img src ={ rss } />&nbsp;Follow</button>
			</div>
		)
	}

class KnowledgeBlockDetails extends React.Component {
	
	constructor(props) {
	    super(props);
	    this.state = { data: [],
	    			   authToken :'',
	    			   followItSuccess:false,
	    			   followItWait:false,
					   unfollowSuccess:false,
	    			   publicVideo:''
	    			};
	  }

	componentDidMount() {
		ReactDOM.findDOMNode(this).scrollIntoView();
		let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
		if (isLoggedIn == true)  {
			let authToken = localStorage.getItem('authToken');
			this.setState({authToken : authToken}, () => {
				this.getAllData();
			});
	   } else {
		   this.getAllData();
	   }
	   axios.get(BaseUrl.base_url+"/api/v1/KnowledgeBlocks/Name/"+this.props.match.params.knowledgeblock_url+"/Videos_Articles").then((response)=>{
            if (response.data.length) {
            	if (response.data[0].length) {
            		this.setState({ 
		            	publicVideo: response.data[0][0].public_WebVideo_Resource_URL
		            });
            	}
            }
        });
	}
	
		getAllData() {
			if(this.state.authToken) {
				axios({
				  method: 'get',
				  url: BaseUrl.base_url+"/api/v1/KnowledgeBlocks/Name/"+this.props.match.params.knowledgeblock_url,
				  headers: {
					'Authorization':this.state.authToken
				  },
				}).then(response => {
					console.log(response.data.knowledgeBlocks);
					console.log('aaa');
				
					let newDAta=[];
					this.setState({ 
						data: response.data
					});
					console.log(this.state.dataKB)
				})
				.catch(error => {
				  throw(error);
				});
			}
			else {
				axios.get(BaseUrl.base_url+"/api/v1/KnowledgeBlocks/Name/"+this.props.match.params.knowledgeblock_url).then((response)=>{
					console.log(response.data);
					this.setState({ 
						data: response.data
					});
				});
			}
		}
        
		

	runCodePrettify() {
      console.log({isCalled:'yes'})
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js';
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    }


	followIt(knowledgeBlockId,value){
		var param = {
				"KnowledgeBlockId":knowledgeBlockId,
				"OptionValue":value
			}
		if (this.state.authToken) {
			axios({
			  method: 'post',
			  url: BaseUrl.base_url+"/api/v1/KnowledgeBlock/FollowUnFollowKnowledgeBlock",
			  headers: {
				'Content-Type': 'application/json',
				'Authorization':this.state.authToken
			  },
				data: param
				}).then(response => {
					console.log(response)
					if(response.data.success == "Following knowledge block"){
						this.setState({ followItSuccess: true });
						this.getAllData();
					}
					else if(response.data.success == "UnFollowed knowledge block") {
						this.setState({ unfollowSuccess: true });
						this.getAllData();
					}
				})
				.catch(error => {
				  throw(error);
				});
			}else{
				this.setState({ followItWait: true });
			}
			
			/* axios.post(BaseUrl.base_url+"/view/api/KnowledgeBlock/FollowKnowledgeBlock?UserToken="+this.state.authToken+"&KnowledgeBlockId="+knowledgeBlockId).then((response)=>{
				this.setState({ followItSuccess: true });
			});
		}else{
		      this.setState({ followItWait: true });
		} */
	}


	handleModeChange(key){
        console.log('key -->', key)
		this.props.history.push('/knowledgeblockdetails/'+this.props.match.params.knowledgeblock_url+'/'+key);
	}
	
	 render() {
		 this.runCodePrettify();
		function callback(key) {
				console.log(key);
			}

		var display ={
			display:'none'
		};
		 return (		
		 <div>
	 	    <SweetAlert
			show={this.state.followItWait}
			title="Wait"
			type="warning"
			text="You need to login in order to follow"
			onConfirm={() => this.setState({ followItWait: false })}
			/>
			<SweetAlert
			show={this.state.followItSuccess}
			title="Success"
			type="success"
			text="You are following this knowledge block"
			onConfirm={() => this.setState({ followItSuccess: false })}
			/>
			<SweetAlert
			show={this.state.unfollowSuccess}
			title="Success"
			type="success"
			text="You have unfollowed this knowledge block successfully"
			onConfirm={() => this.setState({ unfollowSuccess: false })}
			/>
			<div className="main_div">
	            <Header />
			</div>
			<div className="col-md-12 col-sm-12 col-xs-12 cp_page">
				<div className="container">
					<div className="col-md-6 col-sm-7 col-xs-12 no-left">
						<p className="cp_title">{this.state.data.knowledgeBlock_Name}</p>
						<p className="cp_para">{this.state.data.knowledgeBlock_Description}</p>
						{this.state.data.isCurrentUserFollowing?<FollowedImage
															 followingIt ={() => this.followIt(this.state.data.knowledgeBlock_Id,0)}
															 />:
															 <FollowImage
															 followIt = {() => this.followIt(this.state.data.knowledgeBlock_Id,1)}
															 />	 
								}
					</div>
					<div className="col-md-6 col-sm-5 col-xs-12 no-right">
						<div className="col-md-12 col-sm-12 col-xs-12 video_div">
							<Player
							video={this.state.publicVideo}
							/>
						</div>
					</div>
				</div>	
			</div>
			<div className="col-md-12 col-sm-12 col-xs-12 extra-top">
				<div className="container">
					<p className="cp_about">About {this.state.data.knowledgeBlock_Name}</p>
					<p className="cp_about_desc">{this.state.data.knowledgeBlock_Summary}</p>
					<div className="col-md-12 col-sm-12 col-xs-12 cp_tabs knowledgeblock_content_text">
						<Tabs onChange={this.handleModeChange.bind(this)} defaultActiveKey={this.props.match.params.active_tab}>
							<TabPane tab="Get Started in 15mins" key="get_started">
								<GetStarted  knowledgeBlockName={this.props.match.params.knowledgeblock_url} />
							</TabPane>
							<TabPane tab="Videos and Blogs" key="videos_and_blogs">
								<VideosAndBlogs knowledgeBlockName={this.props.match.params.knowledgeblock_url} />
							</TabPane>
							<TabPane tab="Interviews & Practice Assignments" key="interviews_practice_assignments">
								<KnolwedgeBlock_Interviews_Practice knowledgeBlockName={this.props.match.params.knowledgeblock_url} />
							</TabPane>
							<TabPane tab="Trainings & Meetups" key="trainings_and_meetups">
								<TrainingsAndMeetups knowledgeBlockName={this.props.match.params.knowledgeblock_url} />
							</TabPane>
						</Tabs>
					</div>
				</div>
			</div>
			<div className="col-md-12 col-sm-12 col-xs-12 extra">
				<div className="container ad_bg">
					<p className="ad_header">Useful Links (sponsored)</p>
					
					<div className="col-md-12 col-sm-12 col-xs-12 no-padding">
						<Advertisement />
					</div>
					
				</div>
			</div>
			<div className="col-md-12 col-sm-12 col-xs-12 extra-top">
				<div className="container">
					<div className = "fillup">
						<div className="col-md-12 col-sm-12 col-xs-12 knowledgeblock_title">
							<p className="knowledgeblock_title_p">Popular Knowledge Blocks</p>
							<div className="view_all"><Link to="/knowledgeblock/" className="view_all_style">View All</Link></div>
						</div>
						<KnowledgeBlockListing display={display} />
						
					</div>
				</div>
			</div>
			<Footer />
		</div>
		 )
	 }
	  
}

export default KnowledgeBlockDetails;
