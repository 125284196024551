import React, {Component} from 'react'
import '../styles/TestDetails.css'
import QuestionList from "./QuestionList";
import Header from "./header";
import Footer from "./footer";
// import {Icon} from "@ant-design/icons";
import {Button} from "antd";

import axios from "axios";
import BaseUrl from "../config/properties";

class QuizDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startIndex: 0,
            visibleQuestionIndex:0,
            exam:{},
            result:{},
            quizQuestions:{},
            examScore:0,
            isEvalute:false,
        };
        this.onChange = this.onChange.bind(this);
        this.onEvalute = this.onEvalute.bind(this);
    }

    componentWillMount() {
        let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        let authToken = null;
        let userData = [];
        let obj = {};
        if (isLoggedIn === true) {
            authToken = localStorage.getItem('authToken');
            userData = JSON.parse(localStorage.getItem('userData'));
            console.log(userData);
            this.setState({
                authToken:authToken,
                userData:userData,
                url:userData.picture.data.url,
            });
        }
        if (authToken) {
            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('authToken')
                }
            }
            axios.get(BaseUrl.base_url + "/api/v1/Quizzes/Name/" + this.props.params.name+ "/Details", config)
                .then((response) => {
                    if (response.data) {
                        obj = JSON.parse(response.data.quizQuestions)
                        console.log('===obj===>',obj)
                        this.setState({
                            quiz: response.data,quizQuestions:obj
                        })
                    }
                }).catch((error) => {
                this.setState({error: error.message})
            });
        }
    }
    onChange  (e,index) {
        const {quizQuestions} =this.state
        if(e.target.name === "correctAnswer" || (e.target.attributes["data-name"] &&e.target.attributes["data-name"].value === "correctAnswer")) {
           quizQuestions.questions[index].Answers.forEach((ans, i) => {
                ans.chooseAnswer = !!(parseInt(e.target.attributes["data-id"].value, 10) === i)
           });
            this.setState({
                quizQuestions
            });
        }
    }

    onEvalute(){
        const {quizQuestions} = this.state
        let examScore = 0
        console.log(quizQuestions)
        quizQuestions.questions.forEach((que)=>{
            que.Answers.forEach((ans)=>{
                if(ans.chooseAnswer && ans.correctAnswer){
                    examScore = examScore + 1
                }
            })
        })
        console.log(examScore)
        this.setState({examScore,isEvalute:true})

    }

    runCodePrettify() {
      console.log({isCalled:'yes'})
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js';
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    }


    render() {
        this.runCodePrettify();
        const queCount = ['A', 'B', 'C', 'D', 'E',];
        const { quiz, quizQuestions} = this.state
        const answers = []
        quizQuestions && quizQuestions.questions && quizQuestions.questions.forEach((que)=>{
            que.Answers.forEach((ans)=>{
                if(ans.chooseAnswer) {
                    answers.push(ans)
                }
            })
        })
        console.log("== answers ==========>", answers)
        return (
            <div>
                <div className="main_div">
                    <Header/>
                </div>
                <div className="container quiz-body">
                    {
                        quiz ?
                            <div className="test-details mb-5 mt-4"
                                 style={{paddingTop: "10px", paddingBottom: "20px"}}>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-12 col-xs-12 mb-3'>
                                        <Button type="primary" onClick={() => this.props.history.push('/quizzes')}>
                                            {/*<Icon type="left"/>*/}
                                            Back to Quiz List
                                        </Button>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-xs-12" style={{paddingTop: "18px"}}>
                                        <div className='row'>
                                            <div className="col-sm-10 col-md-10 col-xs-12">
                                                <div className="test-title"><h4
                                                    className='text-dark'>{quiz.quiz_Name}</h4></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{paddingTop: "10px"}}>
                                    <div className="col-sm-12 col-md-12 col-xs-12">
                                        <QuestionList {...this.state} queCount={queCount} onChange={this.onChange} onSave={this.onEvalute} answers={answers}/>
                                    </div>
                                </div>

                            </div>
                            : null

                    }

                </div>
                <Footer/>
            </div>

        );

    }
}

export default QuizDetails;
